import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GET_FISH_DETAILS } from "../../query/FishQuery";
import { useQuery } from "@apollo/client";
import { ResizableBox } from "react-resizable";
import { useDispatch } from "react-redux";
import { setCartData } from "../../redux/slice/cartSlice";
import { GET_CART } from "../../query/CartQuery";
import Loading from "../../common/Loading/Loading";
import fishIcon from "../../assets/images/mapFish.svg";
import timesIcon from "../../assets/images/times_icon.svg";
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

function CartFishDetailPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showMap, setShowMap] = useState(false);
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const token = localStorage.getItem("buyerToken");

  const { data: cartData } = useQuery(GET_CART, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-GraphQL-Operation-Name": "GetCart",
      },
    },
  });

  useEffect(() => {
    if (cartData) {
      dispatch(setCartData(cartData?.getCarts));
    }
  }, [cartData]);

  const {
    loading: dataLoading,
    error: dataError,
    data: fishData,
  } = useQuery(GET_FISH_DETAILS, {
    variables: { catchId: id },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-GraphQL-Operation-Name": "GetBuyerFishCatchDetail",
      },
    },
  });

  const handleAddressClick = () => {
    setShowMap(true);
  };

  const handleCancelClick = () => {
    setShowMap(false);
  };

  const handleImageClick = (image) => {
    // console.log(image, "=========");
    setSelectedImage(image);
    setShowImagePopup(true);
  };

  const handleCloseImagePopup = () => {
    setShowImagePopup(false);
    setSelectedImage(null);
  };

  const lat = fishData?.getBuyerCatchByFish?.buyerCatchInfo?.latitude;
  const lng = fishData?.getBuyerCatchByFish?.buyerCatchInfo?.longitude;

  console.log("fishData", fishData);

  return (
    <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
      <div className="flex gap-6 flex-wrap xl:flex-nowrap">
        {dataLoading ? (
          <div className="absolute top-1/2 left-1/2">
            <Loading />
          </div>
        ) : (
          <>
            <div className="rounded-[10px] bg-white w-full xl:w-[calc(100%-400px)]">
              <div className="p-4 sm:p-5 lg:py-6 lg:px-9 border-b border-[#EDEDED]">
                <h3 className="font-medium text-lg sm:text-xl">
                  Catch Details
                </h3>
              </div>
              <div className="p-4 sm:p-5 lg:p-9">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-6">
                  <div>
                    <label
                      className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      htmlFor="">
                      Fish Species
                    </label>
                    <input
                      className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                      type="text"
                      placeholder="Name"
                      value={
                        fishData?.getBuyerCatchByFish?.buyerCatchInfo
                          ?.fishSpecies
                      }
                      name=""
                      id=""
                    />
                  </div>
                  <div className="relative">
                    <label
                      className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      htmlFor="">
                      Weight
                    </label>
                    <input
                      className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                      type="text"
                      placeholder="Weight"
                      value={
                        fishData?.getBuyerCatchByFish?.buyerCatchInfo?.weight
                      }
                      name=""
                      id=""
                    />
                    <span className="absolute top-1/2 right-5 text-[#4B5563]">
                      lbs
                    </span>
                  </div>
                  <div>
                    <label
                      className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      htmlFor="">
                      Tag Number
                    </label>
                    <input
                      className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                      type="text"
                      placeholder="AA 11 A 1234"
                      value={
                        fishData?.getBuyerCatchByFish?.buyerCatchInfo?.tagNumber
                      }
                      name=""
                      id=""
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-4 sm:gap-6 mb-6">
                  <div className="col-span-1">
                    <label
                      className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      htmlFor="">
                      Date
                    </label>
                    <input
                      className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                      type="text"
                      placeholder="Date / Time"
                      name=""
                      value={
                        fishData?.getBuyerCatchByFish?.buyerCatchInfo?.dateTime
                      }
                      id=""
                    />
                  </div>
                  <div className="xl:col-span-2">
                    <label
                      className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      htmlFor="">
                      Location
                    </label>
                    <input
                      className="cursor-pointer border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                      type="text"
                      placeholder="Address"
                      value={
                        fishData?.getBuyerCatchByFish?.buyerCatchInfo?.location
                      }
                      name=""
                      onClick={handleAddressClick}
                      id=""
                    />
                  </div>
                </div>
                {fishData?.getBuyerCatchByFish?.buyerCatchInfo?.story && (
                    <div class="grid grid-cols-1 gap-6 mb-6">
                      <div class="col-span-1">
                        <label
                          class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                          for="">
                          Story
                        </label>
                        <textarea
                          class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] pt-2 px-3 h-[90px] sm:h-[110px] rounded-[10px] w-full"
                          name=""
                          readOnly
                          id="">
                          {fishData?.getBuyerCatchByFish?.buyerCatchInfo?.story}
                        </textarea>
                      </div>
                    </div>
                  )}
                <div className="grid grid-cols-1 gap-6 mb-6">
                  <div className="col-span-1">
                    <label
                      className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                      htmlFor="">
                      Photos
                    </label>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-5">
                      {fishData?.getBuyerCatchByFish?.buyerCatchInfo?.images?.map(
                        (photo, index) => (
                          <img
                            key={index}
                            src={photo}
                            alt="fish_Photo"
                            className="h-auto w-full object-contain cursor-pointer"
                            onClick={() => handleImageClick(photo)}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ml-auto w-[400px]">
              <div class=" bg-white rounded-[10px] overflow-hidden">
                <div class="w-full border-b border-[#EDEDED] justify-between items-center p-4 sm:py-6 sm:px-[18px] flex">
                  <h3 class="font-medium text-lg sm:text-xl">Seller’s Info</h3>
                </div>
                <div class="flex px-[18px] py-7 gap-5">
                  <div class="w-24 h-24">
                    <img
                      class="w-full h-full rounded-[10px] object-cover"
                      src={
                        fishData?.getBuyerCatchByFish?.buyerSellerInfo?.image
                      }
                      alt=""
                    />
                  </div>
                  <div class="w-[calc(100%-90px)]">
                    <span class="text-xl font-medium text-black">
                      {fishData?.getBuyerCatchByFish?.buyerSellerInfo?.name}
                    </span>
                    <div
                      href=""
                      class="text-sm font-medium text-[#4B5563] block my-2">
                      {fishData?.getBuyerCatchByFish?.buyerSellerInfo?.email}
                    </div>
                    <div href="" class="tetx-sm font-medium text-[#4B5563]">
                      {
                        fishData?.getBuyerCatchByFish?.buyerSellerInfo
                          ?.phoneNumber?.dialCode
                      }{" "}
                      {
                        fishData?.getBuyerCatchByFish?.buyerSellerInfo
                          ?.phoneNumber?.number
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-7">
                <div class="w-full mb-[10px]">
                  <h3 class="font-bold text-base">Catch Summary</h3>
                </div>
                <div class="w-full rounded-[10px] bg-white ">
                  <div class="w-full py-2 px-4">
                    <span class="text-[#4B5563] font-semibold text-sm">
                      Items (1)
                    </span>
                    <ul class="p-4 border-b border-[#A2A6DE] pb-3 w-full">
                      <li class="flex my-[10px] text-sm text-[#4B5563] w-full justify-between">
                        <span>Weight (lbs)</span>{" "}
                        <b>
                          {
                            fishData?.getBuyerCatchByFish?.catchSummary
                              ?.quantity
                          }
                        </b>
                      </li>

                      <li class="flex text-sm text-[#4B5563] w-full justify-between">
                        <span> Price Per Lbs</span>{" "}
                        <b>
                          $
                          {fishData?.getBuyerCatchByFish?.catchSummary
                            ?.pricePerLbs
                            ? new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(
                                fishData?.getBuyerCatchByFish?.catchSummary?.pricePerLbs.toFixed(
                                  2
                                )
                              )
                            : ""}
                        </b>
                      </li>
                    </ul>
                    <div class="flex justify-between mt-4 items-center">
                      <span class="text-[#4B5563] font-bold text-sm">
                        Total Price
                      </span>
                      <span class="text-[#4B5563] font-bold text-sm">
                        $
                        {fishData?.getBuyerCatchByFish?.catchSummary?.totalPrice
                          ? new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(
                              fishData?.getBuyerCatchByFish?.catchSummary?.totalPrice.toFixed(
                                2
                              )
                            )
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {showMap && (
        <div className="fixed top-0 left-0  w-full h-full bg-black/50 z-50">
          <div className="w-[90%] h-[90%] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-[15px]  sm:p-[30px] bg-white  rounded-[10px] text-center">
            <button
              onClick={handleCancelClick}
              className="absolute top-4 right-4 p-2 bg-red-500 text-white rounded-full">
              <img src={timesIcon} alt="Close" className="w-4 h-4" />
            </button>
            <div className="relative h-[90%] w-[100%] mt-10">
              <LoadScript googleMapsApiKey="AIzaSyBWF8V2GDtVE7epKyyXZV7HFmDBfEVLscY">
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                  center={{ lat, lng }}
                  zoom={5}
                  options={{ mapTypeId: "satellite", scrollwheel: false }}>
                  <Marker position={{ lat, lng }} icon={fishIcon} />
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      )}
      {showImagePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <ResizableBox
            width={Infinity}
            height={600}
            minConstraints={[Infinity, 200]}
            maxConstraints={[Infinity, Infinity]}
            resizeHandles={["se", "ne", "sw", "nw", "e", "w", "n", "s"]}
            className="relative bg-white bg-[#FAFBFF] p-4 rounded overflow-hidden">
            <button
              className="absolute top-3 right-3 text-black bg-red-500 rounded-full"
              onClick={handleCloseImagePopup}>
              <img src={timesIcon} alt="Close" />
            </button>
            <img
              src={selectedImage}
              alt="Selected Fish"
              className="rounded-md w-full h-full object-contain"
            />
          </ResizableBox>
        </div>
      )}
    </div>
  );
}

export default CartFishDetailPage;
