import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { GET_FISH_DETAILS } from "../../query/FishQuery";
import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { addToCartAsync } from "../../redux/action/addToCartAction";
import { setCartData } from "../../redux/slice/cartSlice";
import { GET_CART } from "../../query/CartQuery";
import Loading from "../../common/Loading/Loading";
import moment from "moment";
import toast from "react-hot-toast";
import fishIcon from "../../assets/images/mapFish.svg";
import trackIcon from "../../assets/images/trackImage.svg";
import timesIcon from "../../assets/images/times_icon.svg";
import {
  GoogleMap,
  Marker,
  LoadScript,
  MarkerF,
  InfoWindow,
} from "@react-google-maps/api";
import { ResizableBox } from "react-resizable";
import { ADMIN_CATCH_TRACK_INFO } from "../../query/OrderQuery";

function FishDetailPage() {
  const { id } = useParams();
  const [showMap, setShowMap] = useState(false);
  const [mapForTrack, setMapForTrack] = useState(false);
  const [mapCenter, setMapCenter] = useState("");
  const [zoomLevel, setZoomLevel] = useState(5);
  const [hoveredMarker, setHoveredMarker] = useState(null);
  // const [hoveredMarker, setHoveredMarker] = useState();
  const [openTab, setOpenTab] = useState(1);
  const dispatch = useDispatch();
  const [showImagePopup, setShowImagePopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const fishId = localStorage.getItem("cartFishId", id);
  const [loadingAddToCart, setLoadingAddToCart] = useState(false);
  const location = useLocation();

  const token = localStorage.getItem("buyerToken");

  const {
    loading,
    error,
    data: cartData,
    refetch,
  } = useQuery(GET_CART, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-GraphQL-Operation-Name": "GetCart",
      },
    },
  });

  useEffect(() => {
    if (cartData) {
      dispatch(setCartData(cartData?.getCarts));
    }
  }, [cartData]);

  const {
    loading: dataLoading,
    error: dataError,
    data: fishData,
  } = useQuery(GET_FISH_DETAILS, {
    variables: { catchId: id },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-GraphQL-Operation-Name": "GetBuyerFishCatchDetail",
      },
    },
  });

  console.log("fishData", fishData);

  const {
    data: trackData,
    loading: trackLoading,
    error: trackError,
    refetch: refetchTrack,
  } = useQuery(ADMIN_CATCH_TRACK_INFO, {
    variables: {
      tagNumber: fishData?.getBuyerCatchByFish?.buyerCatchInfo?.tagNumber,
    },
  });

  console.log("trackData", trackData);

  const { adminCatchesTrackInfo } = trackData || {};

  useEffect(() => {
    refetch();
    refetchTrack();
  }, [id, refetch, refetchTrack]);

  useEffect(() => {
    // Check if the state was passed and set the activeTab
    if (location.state?.setActiveTab) {
      setOpenTab(location.state.setActiveTab);
    }
  }, [location.state]);
  console.log(openTab);

  const handleOpenMapForTrack = () => {
    setMapForTrack(true);
  };

  const handleAddToCart = async () => {
    setLoadingAddToCart(true);
    try {
      await dispatch(addToCartAsync({ fishId, catchId: id }));
      refetch();
    } catch (error) {
      toast.success(error.message);
      console.error("Error adding to cart:", error);
    } finally {
      setLoadingAddToCart(false);
    }
  };

  const handleAddressClick = () => {
    setShowMap(true);
  };

  const handleCancelClick = () => {
    setShowMap(false);
    setMapForTrack(false);
  };

  const handleImageClick = (image) => {
    console.log(image, "=========");
    setSelectedImage(image);
    setShowImagePopup(true);
  };

  const handleCloseImagePopup = () => {
    setShowImagePopup(false);
    setSelectedImage(null);
  };

  const lat = fishData?.getBuyerCatchByFish?.buyerCatchInfo?.latitude;
  const lng = fishData?.getBuyerCatchByFish?.buyerCatchInfo?.longitude;

  useEffect(() => {
    if (
      mapForTrack &&
      adminCatchesTrackInfo?.length > 0 &&
      window.google &&
      window.google.maps
    ) {
      const bounds = new window.google.maps.LatLngBounds();
      adminCatchesTrackInfo?.forEach((info) => {
        bounds?.extend(
          new window.google.maps.LatLng(info.latitude, info.longitude)
        );
      });
      const newZoomLevel = Math.min(15, 17);
      const firstMarkerPosition = adminCatchesTrackInfo[0];
      const center = {
        lat: firstMarkerPosition?.latitude,
        lng: firstMarkerPosition?.longitude,
      };

      setMapCenter(center);
      setZoomLevel(newZoomLevel);
    } else {
      setMapCenter({ lat, lng });
      setZoomLevel(5);
    }
  }, [mapForTrack, adminCatchesTrackInfo]);

    return (
    <div class="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
      <div class="flex gap-6 flex-wrap xl:flex-nowrap">
        {dataLoading ? (
          <div className="absolute top-1/2 left-1/2">
            <Loading />
          </div>
        ) : (
          <>
            <div class="rounded-[10px] bg-white w-full xl:w-[calc(100%-400px)]">
              <div class=" p-0  pb-0 sm:pb-0 lg:pb-0 sm:p-5 lg:py-6 lg:px-9 border-b border-[#EDEDED]">
                <div className="flex flex-wrap sm:flex-nowrap items-center justify-between bg-white">
                  <div className="flex border-b border-[ededed] sm:border-none borer w-full sm:w-auto">
                    <button
                      onClick={() => setOpenTab(1)}
                      className={`w-1/2 px-0 sm:w-auto relative text-base sm:text-lg font-medium  py-5 sm:px-8 outline-none focus:shadow-outline-blue transition-all duration-300 ${
                        openTab === 1
                          ? "bg-transparent text-[#161941] dropline"
                          : "text-[#828282]"
                      }`}>
                      Catch Details
                    </button>
                    <button
                      onClick={() => setOpenTab(2)}
                      className={`w-1/2 px-0 sm:w-auto relative font-medium text-base sm:text-lg py-5 sm:px-8 outline-none focus:shadow-outline-blue transition-all duration-300 ${
                        openTab === 2
                          ? "bg-transparent text-[#161941] dropline"
                          : "text-[#828282]"
                      }`}>
                      Track Info
                    </button>
                  </div>
                  <div className="flex justify-end sm:justify-normal w-full sm:w-auto  gap-4">
                    {openTab === 2 && (
                      <button
                        className="bg-[#161941] w-full sm:w-auto m-4 sm:m-0 flex items-center justify-center rounded-[10px] py-2 px-2 text-white text-sm font-semibold"
                        onClick={handleOpenMapForTrack}>
                        <span>See Info</span>
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {openTab === 1 && (
                <div class="p-4 sm:p-5 lg:p-9">
                  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-6">
                    <div>
                      <label
                        class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        for="">
                        Fish Species
                      </label>
                      <input
                        class="border-none cursor-not-allowed placeholder:text-[#4B5563] font-medium bg-[#F6F6F6]  text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                        type="text"
                        placeholder="Name"
                        value={
                          fishData?.getBuyerCatchByFish?.buyerCatchInfo
                            ?.fishSpecies
                        }
                        name=""
                        readOnly
                        id=""
                      />
                    </div>
                    <div className="relative">
                      <label
                        class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        for="">
                        Weight
                      </label>
                      <input
                        class="border-none  cursor-not-allowed placeholder:text-[#4B5563] font-medium bg-[#F6F6F6]  text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                        type="text"
                        placeholder="Weight"
                        value={
                          fishData?.getBuyerCatchByFish?.buyerCatchInfo?.weight
                        }
                        name=""
                        readOnly
                        id=""
                      />
                      <span class="absolute top-1/2 right-5 text-[#4B5563]">
                        lbs
                      </span>
                    </div>
                    <div>
                      <label
                        class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        for="">
                        Tag Number
                      </label>
                      <input
                        class="border-none cursor-not-allowed placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                        type="text"
                        placeholder="AA 11 A 1234"
                        value={
                          fishData?.getBuyerCatchByFish?.buyerCatchInfo
                            ?.tagNumber
                        }
                        name=""
                        readOnly
                        id=""
                      />
                    </div>
                  </div>
                  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-3 gap-4 sm:gap-6 mb-6">
                    <div class="col-span-1">
                      <label
                        class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        for="">
                        Date
                      </label>
                      <input
                        class="border-none  placeholder:text-[#4B5563] font-medium bg-[#F6F6F6]  text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px]  rounded-[10px] w-full"
                        type="text"
                        placeholder="Date / Time"
                        name=""
                        value={
                          fishData?.getBuyerCatchByFish?.buyerCatchInfo
                            ?.dateTime
                        }
                        id=""
                        readOnly
                      />
                    </div>
                    <div class="xl:col-span-2">
                      <label
                        class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        for="">
                        Location
                      </label>
                      <input
                        class="cursor-pointer border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6]  text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                        type="text"
                        placeholder="Address"
                        value={
                          fishData?.getBuyerCatchByFish?.buyerCatchInfo
                            ?.location
                        }
                        name=""
                        id=""
                        onClick={handleAddressClick}
                      />
                    </div>
                  </div>
                  {fishData?.getBuyerCatchByFish?.buyerCatchInfo?.story && (
                    <div class="grid grid-cols-1 gap-6 mb-6">
                      <div class="col-span-1">
                        <label
                          class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                          for="">
                          Story
                        </label>
                        <textarea
                          class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] pt-2 px-3 h-[90px] sm:h-[110px] rounded-[10px] w-full"
                          name=""
                          readOnly
                          id="">
                          {fishData?.getBuyerCatchByFish?.buyerCatchInfo?.story}
                        </textarea>
                      </div>
                    </div>
                  )}

                  <div class="grid grid-cols-1 gap-6 mb-6">
                    <div class="col-span-1">
                      <label
                        class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        for="">
                        Photos
                      </label>
                      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-5">
                        {fishData?.getBuyerCatchByFish?.buyerCatchInfo?.images?.map(
                          (image, index) => (
                            <div key={index} class="w-full">
                              <img
                                class="h-auto w-full object-contain cursor-pointer"
                                src={image}
                                alt=""
                                onClick={() => handleImageClick(image)}
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {openTab === 2 && (
                <>
                  <div className="transition-all duration-300 bg-white">
                    <div className="bg-white h-[65vh] overflow-y-auto">
                      <div className="overflow-x-auto">
                        <table className="w-full min-w-[768px]">
                          <thead>
                            <tr>
                              <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                Scanned By
                              </th>
                              <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                Date/Time
                              </th>
                              <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                Location
                              </th>
                              <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                                Notes
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {adminCatchesTrackInfo?.length === 0 ? (
                              <div className="w-full h-[50vh] ml-20 sm:ml-56 mt-20 sm:mt-32 relative">
                                <div className="w-full absolute top-0 left-0 z-20">
                                  <div className="flex flex-col justify-start sm:justify-center items-center">
                                    <img
                                      src={trackIcon}
                                      className="w-20 sm:w-auto"
                                      alt="empty_image"
                                    />
                                    <p className="font-bold">
                                      No record found!
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              adminCatchesTrackInfo?.map((info) => (
                                <tr>
                                  <td
                                    className="text-[14px] text-sm sm:text-base text-[#4B5563] py-[22px] px-[30px] font-semibold"
                                    style={{ width: "190px" }}>
                                    {info?.firstName} {info?.lastName}
                                  </td>
                                  <td
                                    className="text-[14px] text-sm sm:text-base text-[#4B5563] py-[22px] px-[30px] font-semibold"
                                    style={{ width: "190px" }}>
                                    {moment(info?.createdAt).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                  <td
                                    className="text-[14px] text-sm sm:text-base text-[#4B5563] py-[22px] px-[28px] font-semibold"
                                    style={{ width: "190px" }}>
                                    <div className="location-scroll whitespace-nowrap w-[150px] overflow-x-auto cursor-pointer">
                                      {info?.address}
                                    </div>
                                  </td>
                                  <td
                                    className="text-[14px] text-sm sm:text-base text-[#4B5563] py-[22px] px-[30px] font-semibold"
                                    style={{ width: "190px" }}>
                                    {info?.statusNote}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div class="ml-auto w-[400px]">
              <div class=" bg-white rounded-[10px] overflow-hidden">
                <div class="w-full border-b border-[#EDEDED] justify-between items-center p-4 sm:py-6 sm:px-[18px] flex">
                  <h3 class="font-medium text-lg sm:text-xl">Seller’s Info</h3>
                </div>
                <div class="flex px-[18px] py-7 gap-5">
                  <div class="w-24 h-24">
                    <img
                      class="w-full h-full rounded-[10px] object-cover"
                      src={
                        fishData?.getBuyerCatchByFish?.buyerSellerInfo?.image
                      }
                      alt=""
                    />
                  </div>
                  <div class="w-[calc(100%-90px)]">
                    <span class="text-xl font-medium text-black">
                      {fishData?.getBuyerCatchByFish?.buyerSellerInfo?.name}
                    </span>
                    <div
                      href=""
                      class="text-sm font-medium text-[#4B5563] block my-2">
                      {fishData?.getBuyerCatchByFish?.buyerSellerInfo?.email}
                    </div>
                    <div href="" class="text-sm font-medium text-[#4B5563]">
                      {
                        fishData?.getBuyerCatchByFish?.buyerSellerInfo
                          ?.phoneNumber?.dialCode
                      }{" "}
                      {
                        fishData?.getBuyerCatchByFish?.buyerSellerInfo
                          ?.phoneNumber?.number
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-7">
                <div class="w-full mb-[10px]">
                  <h3 class="font-bold text-base">Catch Summary</h3>
                </div>
                <div class="w-full rounded-[10px] bg-white ">
                  <div class="w-full py-2 px-4">
                    <span class="text-[#4B5563] font-semibold text-sm">
                      Items (1)
                    </span>
                    <ul class="p-4 border-b border-[#A2A6DE] pb-3 w-full">
                      <li class="flex my-[10px] text-sm text-[#4B5563] w-full justify-between">
                        <span>Weight (lbs)</span>{" "}
                        <b>
                          {
                            fishData?.getBuyerCatchByFish?.catchSummary
                              ?.quantity
                          }
                        </b>
                      </li>

                      <li class="flex text-sm text-[#4B5563] w-full justify-between">
                        <span> Price Per Lbs</span>{" "}
                        <b>
                          $
                          {fishData?.getBuyerCatchByFish?.catchSummary
                            ?.pricePerLbs
                            ? new Intl.NumberFormat("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(
                                fishData?.getBuyerCatchByFish?.catchSummary?.pricePerLbs.toFixed(
                                  2
                                )
                              )
                            : ""}
                        </b>
                      </li>
                    </ul>
                    <div class="flex justify-between mt-4 items-center">
                      <span class="text-[#4B5563] font-bold text-sm">
                        Total Price
                      </span>
                      <span class="text-[#4B5563] font-bold text-sm">
                        $
                        {fishData?.getBuyerCatchByFish?.catchSummary?.totalPrice
                          ? new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(
                              fishData?.getBuyerCatchByFish?.catchSummary?.totalPrice.toFixed(
                                2
                              )
                            )
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full mt-4">
                <button
                  onClick={handleAddToCart}
                  class="flex justify-center items-center w-full bg-[#161941] text-base  font-normal sm:font-bold tracking-[1px] text-white rounded-[10px] h-[45px] sm:h-12">
                  {loadingAddToCart ? (
                    <div className="upload_loader"></div>
                  ) : (
                    "Add to Cart"
                  )}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {showMap && (
        <div className="fixed top-0 left-0  w-full h-full bg-black/50 z-50">
          <div className="w-[90%] h-[90%] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-[15px]  sm:p-[30px] bg-white  rounded-[10px] text-center">
            <button
              onClick={handleCancelClick}
              className="absolute top-4 right-4 p-2 bg-red-500 text-white rounded-full">
              <img src={timesIcon} alt="Close" className="w-4 h-4" />
            </button>
            <div className="relative h-[90%] w-[100%] mt-10">
              <LoadScript googleMapsApiKey="AIzaSyBWF8V2GDtVE7epKyyXZV7HFmDBfEVLscY">
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                  center={{ lat, lng }}
                  zoom={5}
                  options={{ mapTypeId: "satellite", scrollwheel: false }}>
                  <Marker position={{ lat, lng }} icon={fishIcon} />
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      )}
      {mapForTrack && adminCatchesTrackInfo?.length > 0 && (
        <div className="fixed top-0 left-0 w-full h-full bg-black/50 z-50">
          <div className="w-[90%] h-[90%] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-[15px] sm:p-[30px] bg-white rounded-[10px] text-center">
            <button
              onClick={handleCancelClick}
              className="absolute top-4 right-4 p-2 bg-red-500 text-white rounded-full">
              <img src={timesIcon} alt="Close" className="w-4 h-4" />
            </button>
            <div className="relative h-[90%] w-[100%] mt-10">
              <LoadScript googleMapsApiKey="AIzaSyBWF8V2GDtVE7epKyyXZV7HFmDBfEVLscY">
                <GoogleMap
                  mapContainerStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                  center={{
                    lat: adminCatchesTrackInfo[0].latitude,
                    lng: adminCatchesTrackInfo[0].longitude,
                  }}
                  zoom={5}
                  options={{ mapTypeId: "satellite", scrollwheel: false }}>
                  {mapForTrack &&
                    adminCatchesTrackInfo?.map((info, index) => (
                      <MarkerF
                        key={index}
                        position={{ lat: info.latitude, lng: info.longitude }}
                        icon={fishIcon}
                        onMouseOver={() =>
                          setHoveredMarker({
                            lat: info.latitude,
                            lng: info.longitude,
                            info,
                          })
                        }
                        onMouseOut={() => setHoveredMarker(null)}>
                        {hoveredMarker &&
                          hoveredMarker.lat === info.latitude &&
                          hoveredMarker.lng === info.longitude && (
                            <InfoWindow
                              position={{
                                lat: info.latitude,
                                lng: info.longitude,
                              }}>
                              <div>
                                <p>
                                  Date:{" "}
                                  {moment(info.createdAt).format("MM/DD/YYYY")}
                                </p>
                                <p>
                                  Time: {moment(info.createdAt).format("HH:mm")}
                                </p>
                                <p>Scanner User: {info.address}</p>
                              </div>
                            </InfoWindow>
                          )}
                      </MarkerF>
                    ))}
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        </div>
      )}

      {showImagePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <ResizableBox
            width={Infinity}
            height={600}
            minConstraints={[Infinity, 200]}
            maxConstraints={[Infinity, Infinity]}
            resizeHandles={["se", "ne", "sw", "nw", "e", "w", "n", "s"]}
            className="relative bg-white bg-[#FAFBFF] p-4 rounded overflow-hidden">
            <button
              className="absolute top-3 right-3 text-black bg-red-500 rounded-full"
              onClick={handleCloseImagePopup}>
              <img src={timesIcon} alt="Close" />
            </button>
            <img
              src={selectedImage}
              alt="Selected Fish"
              className="rounded-md w-full h-full object-contain"
            />
          </ResizableBox>
        </div>
      )}
    </div>
  );
}

export default FishDetailPage;
