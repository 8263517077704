import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GET_BUYER_CATCH_BY_FISH } from "../../query/FishQuery";
import dollorIcon from "../../assets/images/dollar_icon.svg";
import proceIcon from "../../assets/images/total_icon.svg";
import Loading from "../../common/Loading/Loading";
import { addToCartAsync } from "../../redux/action/addToCartAction";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

function FishAvailableData() {
  const { id } = useParams();
  localStorage.setItem("cartFishId", id);
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState({});

  const token = localStorage.getItem("buyerToken");

  const {
    loading: fishLoading,
    error,
    data: fishData,
    refetch,
  } = useQuery(GET_BUYER_CATCH_BY_FISH, {
    variables: { fishId: id },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-GraphQL-Operation-Name": "GetBuyerCatchesBuFish",
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleAddToCart = async (fishId) => {
    setLoadingState((prevState) => ({ ...prevState, [fishId]: true }));
    try {
      const response = await dispatch(
        addToCartAsync({ fishId: id, catchId: fishId })
      );
      // toast.success("Fish added to your cart");
      console.log(response);
    } catch (error) {
      toast.error(error.message);
      console.error("Error adding to cart:", error);
    } finally {
      setLoadingState((prevState) => ({ ...prevState, [fishId]: false }));
    }
  };

  return (
    <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
      {fishLoading ? (
        <div className="absolute top-1/2 left-1/2">
          <Loading />
        </div>
      ) : (
        <div className="pt-0 sm:mt-9 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-y-8 mb-6 gap-8">
          {fishData?.getBuyerCatchesBuFish?.map((fish, index) => (
            <div
              key={index}
              className="relative shadow-xl bg-white rounded-md overflow-hidden">
              <span className="w-full">
                <img
                  className="w-full h-40 object-cover"
                  src={fish?.image}
                  alt=""
                />
              </span>
              <div className="flex px-5 pt-3 justify-between">
                <div>
                  <span className="text-black text-xs">Price</span>
                  <b className="text-lg sm:text-xl text-black flex items-center gap-1">
                    <img className="w-5 h-5" src={dollorIcon} alt="" />{" "}
                    {fish?.price
                      ? new Intl.NumberFormat("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(fish?.price.toFixed(2))
                      : ""}
                  </b>
                </div>
                <div>
                  <span className="text-black text-xs">Weight</span>
                  <b className="text-lg sm:text-xl text-black flex items-center gap-1">
                    <img className="w-6 h-auto" src={proceIcon} alt="" />{" "}
                    {fish?.weight} lbs
                  </b>
                </div>
              </div>
              <div className=" flex px-5 mt-4 pb-4 justify-between gap-4">
                <button className=" w-1/2  border border-[#161941] rounded-[10px] sm:w-[120px] h-8 flex items-center justify-center text-xs text-primary font-semibold">
                  <Link to={`/fish-details/${fish?.id}`}>More Details</Link>
                </button>
                <button
                  onClick={() => handleAddToCart(fish?.id)}
                  className="w-1/2  border-none bg-[#161941] rounded-[10px] sm:w-[120px] h-8 flex items-center justify-center text-xs text-white font-semibold">
                  {loadingState[fish?.id] ? (
                    <div className="upload_loader"></div>
                  ) : (
                    "Add to Cart"
                  )}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default FishAvailableData;
