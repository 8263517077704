import FishAvailable from "../layouts/FishAvailable";
import FishAvailableData from "../layouts/FishAvailableData";
import MyCart from "../layouts/MyCart";
import MyOrder from "../layouts/MyOrder";
import Settings from "../layouts/Settings";
import backIcon from "../../assets/images/back_arrow.svg";
import FishDetailPage from "../layouts/FishDetailPage";
import OrderDetails from "../layouts/OrderDetails";
import CartFishDetailPage from "../layouts/CartFishDetailPage";

const baseRoutes = [
  {
    name: "Fish Available",
    path: "/fish-available",
    component: FishAvailable,
  },
  {
    name: "Fish data",
    imageUrl: backIcon,
    path: "/fish-data/:id",
    component: FishAvailableData,
  },
  {
    name: "Fish details",
    imageUrl: backIcon,
    path: "/fish-details/:id",
    component: FishDetailPage,
  },
  {
    name: "Fish Details",
    imageUrl: backIcon,
    path: "/fish-detail/:id",
    component: CartFishDetailPage,
  },
  {
    name: "Order Details",
    imageUrl: backIcon,
    path: "/order-details/:id",
    component: OrderDetails,
  },
  {
    name: "My Order",
    path: "/my-order",
    component: MyOrder,
  },
  {
    name: "My Cart",
    path: "/my-cart",
    component: MyCart,
  },
  {
    name: "Settings",
    path: "/settings",
    component: Settings,
  },
];

export default baseRoutes;
