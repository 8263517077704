import { gql } from "@apollo/client";

export const SIGN_UP_BUYER = gql`
  mutation BuyerSignup($email: String!) {
    buyerSignup(input: { email: $email }) {
      message
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation SellerVerifyOtpForResetPassword($email: String!, $otp: String!) {
    sellerVerifyOtpForResetPassword(input: { email: $email, otp: $otp }) {
      message
    }
  }
`;

export const GET_ALL_FISH_SPECIES = gql`
  query GetAllFishSpecies {
    getAllFishSpecies {
      id
      name
      pricePerLbs
    }
  }
`;

export const VERIFY_OTP_SIGNUP = gql`
  mutation BuyerVerifyOtpForSignup(
    $personalInfoReq: BuyerPersonalInfoInput!
    $companyInfoReq: BuyerCompanyInfoInput!
  ) {
    buyerVerifyOtpForSignup(
      input: {
        personalInfoReq: $personalInfoReq
        companyInfoReq: $companyInfoReq
      }
    ) {
      id
      token
      personalInfo {
        name
        email
        address
        ein
        role
        image
        phoneNumber {
          dialCode
          number
          countryCode
        }
      }
      companyInfo {
        name
        website
        species
        logo
        certificate
      }
    }
  }
`;

export const LOG_IN_QUERY = gql`
  mutation BuyerLoginResponseogin($email: String!, $password: String!) {
    BuyerLoginResponseogin(input: { email: $email, password: $password }) {
      id
      token
      personalInfo {
        name
        email
        address
        ein
        role
        image
        phoneNumber {
          dialCode
          number
          countryCode
        }
      }
      companyInfo {
        name
        website
        species
        logo
        certificate
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation BuyerForgotPassword($email: String!) {
    buyerForgotPassword(input: { email: $email }) {
      message
    }
  }
`;

export const VERIFY_OTP_FORGOT = gql`
  mutation BuyerVerifyOtpForResetPassword($email: String!, $otp: String!) {
    buyerVerifyOtpForResetPassword(input: { email: $email, otp: $otp }) {
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation BuyerResetPassword(
    $email: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    buyerResetPassword(
      input: {
        email: $email
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      }
    ) {
      message
    }
  }
`;

export const GET_BUYER_DATA = gql`
  query Buyer {
    buyer {
      id
      token
      personalInfo {
        name
        email
        address
        ein
        role
        image
        phoneNumber {
          dialCode
          number
          countryCode
        }
      }
      companyInfo {
        name
        website
        species
        logo
        certificate
      }
    }
  }
`;

export const EDIT_BUYER = gql`
  mutation BuyerEditProfile(
    $personalInfoReq: EditBuyerPersonalInfoInput!
    $companyInfoReq: EditBuyerCompanyInfoInput!
  ) {
    buyerEditProfile(
      input: {
        personalInfoReq: $personalInfoReq
        companyInfoReq: $companyInfoReq
      }
    ) {
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation BuyerChangePassword(
    $currentPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    BuyerChangePassword(
      input: {
        currentPassword: $currentPassword
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      }
    ) {
      message
    }
  }
`;

export const GET_PLACE_ID = gql`
  query GetPlaceId($input: String!) {
    getPlaceId(input: $input) {
      description
      placeId
    }
  }
`;

export const GET_PLACE_DETAILS = gql`
  query GetPlaceDetails($placeId: String!) {
    getPlaceDetails(placeId: $placeId) {
      country
      countryCode
      state
      city
      latitude
      longitude
      PinCode
    }
  }
`;
