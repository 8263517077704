import { gql } from "@apollo/client";

export const GET_CART = gql`
  query GetCarts {
    getCarts {
      id
      totalPrice
      fish {
        id
        image
        name
      }
      catch {
        id
        weight
        tagNumber
        isAvailable
        pricePerLbs
      }
    }
  }
`;

export const DELETE_CART_ITEM = gql`
  mutation DeleteCartItem($id: ID!) {
    deletedCart(id: $id) {
      message
    }
  }
`;

export const ADD_ORDER = gql`
  mutation AddOrder($input: OrderRequestInput!) {
    addOrder(input: $input) {
      isAllAvailable
    }
  }
`;
