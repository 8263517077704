import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/pages/Login";
import ForgotPassword from "./components/pages/ForgotPassword";
import VerifyOtp from "./components/pages/VerifyOtp";
import ResetPassword from "./components/pages/ResetPassword";
import SignUp from "./components/pages/SignUp";
import OtpScreen from "./components/pages/OtpScreen";
import { Suspense } from "react";
import AllRoutes from "./components/routes/AllRoutes";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-otp" element={<VerifyOtp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/verify-signup-otp" element={<OtpScreen />} />
        </Routes>
        <Suspense fallback={null}>
          <AllRoutes />
        </Suspense>
      </BrowserRouter>
      <Toaster reverseOrder={true} />
    </div>
  );
}

export default App;
