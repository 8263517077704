import React, { useEffect, useState } from "react";
import eyeIcon from "../../assets/images/eye_icon.svg";
import { GET_ORDER } from "../../query/OrderQuery";
import { useQuery } from "@apollo/client";
import { format, parse } from "date-fns";
import empty from "../../assets/images/empty_pending.png";
import Loading from "../../common/Loading/Loading";
import ResponsivePagination from "react-responsive-pagination";
import { Link } from "react-router-dom";

function convertToLocalDateTime(isoString) {
  const date = new Date(isoString);

  const localDate = date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });

  const localTime = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  return { localDate, localTime };
}

function CompleteOrder() {
  const [fishData, setFishData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [total, setTotal] = useState(1);
  const [limit, setLimit] = useState(10);

  const token = localStorage.getItem("buyerToken");

  const {
    loading,
    error,
    data: orderData,
    refetch,
  } = useQuery(GET_ORDER, {
    variables: { page: currentPage, limit, status: "completed" },
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-GraphQL-Operation-Name": "GetBuyerOrders",
      },
    },
  });
  console.log("orderData", orderData);

  useEffect(() => {
    setFishData(orderData?.getBuyerOrders?.orders);
    setTotalPages(orderData?.getBuyerOrders?.totalPages || 1);
    setTotal(orderData?.getBuyerOrders?.total || 1);
    refetch();
  }, [orderData]);

  const startIdx = (currentPage - 1) * limit + 1;
  const endIdx = Math.min(currentPage * limit, total);

  console.log("comp", fishData);

  return (
    <>
      <div className="bg-white h-[48vh] sm:h-[55vh] lg:h-[65vh] overflow-y-auto">
        <div className="overflow-x-auto">
          <table className="w-full min-w-[768px]">
            <thead>
              <tr>
                <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                  Order ID
                </th>
                <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left rounded-tr-md rounded-br-md">
                  Fish Type
                </th>
                <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                  Amount
                </th>
                <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-left">
                  Order Completed
                </th>
                <th className="text-[14px] border-b border-b-[#EDEDED] font-semibold text-[#757575] py-4 sm:py-[22px] px-[30px] bg-[#FDFDFD] text-right rounded-tr-md rounded-br-md">
                  Action
                </th>
              </tr>
            </thead>
            {loading ? (
              <div className="absolute top-1/2 left-1/2">
                <Loading />
              </div>
            ) : (
              <tbody>
                {fishData?.length === 0 ? (
                  <div className="relative translate-y-0 p-0  sm:p-0 sm:absolute top-1/2 left-1/2 sm:-translate-y-1/2 mt-0 pt-10 sm:pl-0 sm:mt-16">
                    <div className="flex flex-col justify-start sm:justify-center items-center">
                      <img
                        src={empty}
                        className="w-20 sm:w-auto"
                        alt="empty_image"
                      />
                      <p className="font-bold">No Order found!</p>
                    </div>
                  </div>
                ) : (
                  fishData?.map((fish, index) => {
                    const { localDate, localTime } = convertToLocalDateTime(
                      fish?.orderCompleted
                    );
                    return (
                      <tr key={index}>
                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                          <span className="font-medium text-sm sm:text-base text-left">
                            {fish?.orderId}
                          </span>
                        </td>
                        <td className="py-4 sm:py-5 px-8 border-b border-b-[#EDEDED]">
                          <span className="font-medium text-sm sm:text-base text-left">
                            {fish?.fistItemName}{" "}
                            {fish?.totalItemsInOrder > 0
                              ? "+" + fish?.totalItemsInOrder + " more"
                              : ""}
                          </span>
                        </td>
                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                          <span className="font-medium  text-sm sm:text-base text-left">
                            $
                            {fish?.Amount
                              ? new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(fish?.Amount.toFixed(2))
                              : ""}
                          </span>
                        </td>
                        <td className="py-4 sm:py-5 px-7 border-b border-b-[#EDEDED]">
                          <span class="font-medium text-base text-left">
                            {localDate}
                            <div class="text-xs text-[#828282] block pl-4 pt-1">
                              {localTime}
                            </div>
                          </span>
                        </td>
                        <td className="py-4 sm:py-5 px-11 border-b border-b-[#EDEDED] text-right">
                          <button className="text-sm sm:text-base w-auto">
                            <Link to={`/order-details/${fish?.id}`}>
                              <img src={eyeIcon} alt="" />{" "}
                            </Link>
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {!loading && fishData?.length > 0 && (
        <div className="flex flex-wrap bg-[#FAFBFF] pt-4 mb-4 sm:flex-nowrap items-center justify-center gap-y-4 sm:justify-between mt-6">
          <p className="text-[#161941] w-full sm:w-auto text-center sm:text-left text-sm font-normal">
            Showing{" "}
            <span className="text-[#161941]">
              {startIdx} to {endIdx}
            </span>{" "}
            from <span className="text-[#161941]">{total}</span> data
          </p>
          <ResponsivePagination
            current={currentPage}
            total={totalPages}
            onPageChange={setCurrentPage}
          />
        </div>
      )}
    </>
  );
}

export default CompleteOrder;
