import React, { useState } from "react";
import logo from "../../assets/images/new_logo.png";
import logoImg from "../../assets/images/login_img.png";
import emailIcon from "../../assets/images/mail_icon.svg";
import passwordIcon from "../../assets/images/password_icon.svg";
import eyeIcon from "../../assets/images/eye_icon.svg";
import passwordShow from "../../assets/images/password_hide_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { LOG_IN_QUERY } from "../../query/AuthQuery";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginSeller, { loading }] = useMutation(LOG_IN_QUERY);
  const navigate = useNavigate();
  const [showDataPassword, setShowDataPassword] = useState(false);

  const togglePasswordVisibilityPassword = () => {
    setShowDataPassword(!showDataPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!email) return toast.error("Email is required.");
      if (!password) return toast.error("Enter a valid password.");
      const response = await loginSeller({
        variables: {
          email: email.trim(),
          password: password.trim(),
        },
        context: {
          headers: {
            "X-GraphQL-Operation-Name": "SellerLoginResponse",
          },
        },
      });
      // console.log(response, "-=");
      toast.success("Successfully Login!");
      navigate("/fish-available");
      localStorage.setItem("buyerActiveIndex", 0);

      const adminToken = response?.data?.BuyerLoginResponseogin?.token;
      localStorage.setItem("buyerToken", adminToken);

      // setLoading(false);
    } catch (error) {
      console.error("Error during login:", error);
      toast.error(error?.message);
    }
  };
  return (
    <div className="bg-white flex items-center h-screen justify-center">
      <div className="w-full px-[15px] sm:w-full lg:w-1/2 xl:w-1/2  sm:px-[15px] xl:px-0 flex items-center justify-center">
        <div className="w-full sm:w-full md:w-[500px] lg:w-[450px] xl:w-[550px]">
          <div className="static sm:fixed  top-[20px] left-[20px] w-full text-center inline-block sm:w-full xl:w-[120px]">
            <img
              className="mx-auto sm:mx-0  w-[110px] xl:w-[120px]"
              src={logo}
              alt=""
            />
          </div>
          <div className="mt-[10px] mb-[15px] sm:my-[20px] xl:my-[34px] text-center xl:text-left">
            <h2 className="font-bold text-[22px] sm:text-[24px] xl:text-[32px]  text-[#4F4F4F]">
              Login
            </h2>
            <p className="text-[#4F4F4F] text-[16px]">
              Enter your details to login to your account
            </p>
          </div>
          <div className="w-full relative mb-[10px]">
            <input
              className="text-sm sm:text-sm md:text-base xl:text-lg w-full  border-[1px] sm:border-2  border-[#E0E0E0] border-solid rounded-[10px] px-[50px]  xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[70px]"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              name=""
              id=""
            />
            <span className="absolute -translate-y-1/2 top-1/2 left-5">
              <img className="w-[22px] xl:w-auto" src={emailIcon} alt="" />
            </span>
          </div>
          <div className="w-full relative">
            <input
              className="placeholder:text-[#BDBDBD] text-sm sm:text-sm md:text-base xl:text-lg  h-[45px]  w-full  border-[1px] sm:border-2  border-[#E0E0E0] border-solid rounded-[10px] px-[50px]  xl:px-[65px] pr-0 xl:pr-[65px] sm:h-[50px] xl:h-[70px]"
              type={showDataPassword ? "text" : "password"}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              placeholder="*****************"
              name=""
              id=""
            />
            <span className="absolute -translate-y-1/2 top-1/2 left-5">
              <img className="w-[22px] xl:w-auto" src={passwordIcon} alt="" />
            </span>
            <span
              onClick={togglePasswordVisibilityPassword}
              className="cursor-pointer absolute -translate-y-1/2 top-1/2 right-5">
              <img src={showDataPassword ? passwordShow : eyeIcon} alt="" />
            </span>
          </div>
          <div className="w-full text-right mt-2">
            <Link
              to="/forgot-password"
              className="forgot-password text-primary text-base xl:text-xl">
              Forgot Password ?
            </Link>
          </div>
          <div className="w-full mt-[15px] xl:mt-[34px]">
            <button
              onClick={handleSubmit}
              className="flex justify-center items-center w-full rounded-[10px] font-bold bg-[#161941]  text-white text-sm sm:text-sm md:text-base h-[45px] sm:h-[50px]  xl:h-[70px]">
              {loading ? <div className="upload_loader"></div> : "LOGIN"}
            </button>
          </div>
          <div className="mt-[10px] w-full md:mt-[20px] text-center">
            <p className="flex justify-center w-full gap-3 text-gray2 text-sm md:text-lg">
              Don’t have an account?{" "}
              <Link
                to={"/sign-up"}
                className="text-[#161941] font-bold "
                href="">
                Sign Up
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="w-1/2 xl:w-1/2 hidden lg:inline-block">
        <img className="h-screen w-full" src={logoImg} alt="" />
      </div>
    </div>
  );
}

export default Login;
