import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

// const url = "https://zn2j5663-5071.inc1.devtunnels.ms/query";
// const url = "https://5nexbtan8q.us-east-1.awsapprunner.com/query";
const url = "https://api.ctfoffshore.com/query";

const uploadLink = createUploadLink({
  uri: url,
});

const authLink = new ApolloLink((operation, forward) => {
  const operationName = operation.operationName;
  const token = localStorage.getItem("buyerToken");

  let headers = {
    Authorization: token ? `Bearer ${token}` : "",
  };

  switch (operationName) {
    case "GetAllFishSpecies":
      headers["X-GraphQL-Operation-Name"] = "GetAllFishSpecies";
      break;
    case "GetAllCategory":
      headers["X-GraphQL-Operation-Name"] = "GetCategories";
      break;
    case "AddToCart":
      headers["X-GraphQL-Operation-Name"] = "AddToCart";
      break;
    case "GetCarts":
      headers["X-GraphQL-Operation-Name"] = "GetCart";
      break;
    case "AddOrder":
      headers["X-GraphQL-Operation-Name"] = "AddOrder";
      break;
    case "GetBuyerOrders":
      headers["X-GraphQL-Operation-Name"] = "GetBuyerOrders";
      break;
    case "AdminChangePassword":
      headers["X-GraphQL-Operation-Name"] = "GetAdminData";
      break;
    case "Buyer":
      headers["X-GraphQL-Operation-Name"] = "GetBuyerData";
      break;
    case "BuyerEditProfile":
      headers["X-GraphQL-Operation-Name"] = "EditBuyer";
      break;
    case "BuyerChangePassword":
      headers["X-GraphQL-Operation-Name"] = "BuyerChangePassword";
      break;
    default:
      headers["X-GraphQL-Operation-Name"] = "DefaultOperation";
  }

  operation.setContext({
    headers,
  });

  return forward(operation);
});

const link = ApolloLink.from([authLink, uploadLink]);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

export default client;
