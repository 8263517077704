import { CLEAR_SIGNUP_STATE } from "../action";

const initialState = {
  companyImage: null,
  taxCertificate: null,
};

const buySignupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SIGNUP_DETAILS":
      return {
        ...state,
        ...action.payload,
      };
    case CLEAR_SIGNUP_STATE:
      return initialState;
    default:
      return state;
  }
};

export default buySignupReducer;
