import React, { useEffect, useState } from "react";
import dollarImage from "../../assets/images/dollar_icon.svg";
import totalImage from "../../assets/images/total_icon.svg";
import bgGradient from "../../assets/images/bg_gradient.png";
import { GET_ALL_FISH } from "../../query/FishQuery";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import Loading from "../../common/Loading/Loading";
import toast from "react-hot-toast";
import eptyImage from "../../assets/images/empty_pending.png";

function FishAvailable() {
  const [fishAvailable, setFishAvailable] = useState([]);
  localStorage.removeItem("fishName");
  const {
    loading: fishLoading,
    data: fishData,
    refetch,
  } = useQuery(GET_ALL_FISH, {
    context: {
      headers: {
        "X-GraphQL-Operation-Name": "GetAllFishSpecies",
      },
    },
  });

  console.log("--------", fishData);

  const navigate = useNavigate();

  useEffect(() => {
    setFishAvailable(fishData?.getBuyerFishInventories);
    refetch();
  }, [fishData, refetch]);

  const handleFishClick = (fish) => {
    localStorage.setItem("fishName", fish?.speciesName);
    if (fish.quantityInStock > 0) {
      navigate(`/fish-data/${fish.id}`, { state: { fishData: fish } });
    } else {
      toast.error("This fish is out of stock");
    }
  };

  return (
    <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
      {fishLoading ? (
        <div className="absolute top-1/2 left-1/2">
          <Loading />
        </div>
      ) : (
        <div className="mt-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-y-20 mb-6 gap-8">
          {fishAvailable?.length === 0 ? (
            <div className=" absolute -translate-x-1/2 lg:translate-x-0 lg:fixed  flex justify-center flex-wrap top-1/2 left-1/2 -translate-y-1/2">
              <img src={eptyImage} alt="" />
              <p className="text-xl w-full text-primary text-center font-bold">
                No Fish Available
              </p>
            </div>
          ) : (
            fishAvailable?.map((fish, index) => (
              <div
                key={index}
                className="relative pt-10 shadow-xl bg-white rounded-lg p-5 cursor-pointer"
                onClick={() => handleFishClick(fish)}>
                <span className="absolute w-full -top-[45px] z-20 left-0">
                  <img
                    className="w-full h-[100px] object-contain"
                    src={fish?.image}
                    alt=""
                  />
                </span>
                <h3 className="text-[#161941] z-20 relative font-semibold text-xl mt-6 w-full text-center">
                  {fish?.speciesName}
                </h3>
                {fish?.quantityInStock <= 0 ? (
                  <div>
                    <span class="absolute top-0 z-10 left-0 w-full h-full">
                      <img
                        class="w-full align-top rounded-[10px]  h-full object-cover"
                        src={bgGradient}
                        alt=""
                      />
                    </span>
                    <div className="mt-8 mb-3  z-20 relative text-center">
                      <b className="md:text-xl lg:text-2xl text-primary font-medium">
                        Out of Stock!
                      </b>
                    </div>
                  </div>
                ) : (
                  <div className="flex mt-3 mb-0 lg:mb-4 justify-between">
                    <div>
                      <span className="text-black text-xs">Price / lbs</span>
                      <b className="md:text-lg lg:text-2xl text-black flex items-center gap-1">
                        <img className="w-5 h-5" src={dollarImage} alt="" />{" "}
                        {fish?.buyerPrice
                          ? new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(fish.buyerPrice)
                          : ""}
                      </b>
                    </div>
                    <div>
                      <span className="text-black text-xs">Left in stock</span>
                      <b className="md:text-lg lg:text-2xl text-black flex items-center gap-1">
                        <img className="w-6 h-auto" src={totalImage} alt="" />{" "}
                        {fish?.quantityInStock} lbs
                      </b>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
}

export default FishAvailable;
