import { gql } from "@apollo/client";

export const GET_ORDER = gql`
  query GetBuyerOrders($page: Int!, $limit: Int!, $status: String!) {
    getBuyerOrders(page: $page, limit: $limit, status: $status) {
      total
      perPage
      currentPage
      totalPages
      orders {
        id
        orderId
        fistItemName
        totalItemsInOrder
        Amount
        orderPlaced
        orderCompleted
      }
    }
  }
`;

export const GET_COMPLETE_ORDER = gql`
  query GetBuyerOrders($page: Int!, $limit: Int!, $status: String!) {
    getBuyerOrders(page: $page, limit: $limit, status: $status) {
      total
      perPage
      currentPage
      totalPages
      orders {
        id
        orderId
        fistItemName
        totalItemsInOrder
        Amount
        orderPlaced
        orderCompleted
      }
    }
  }
`;

export const ADMIN_BUYER_ORDER_DETAIL = gql`
  query BuyerOrderDetail($id: ID!) {
    buyerOrderDetail(id: $id) {
      items {
        name
        catchId
        image
        price
        pricePerLbs
        weight
      }
      orderId
      status
      address
      delieveryEstimation
      delieveryStartTime
      delieveryEndTime
      totalAmount
    }
  }
`;

export const ADMIN_CATCH_TRACK_INFO = gql`
  query AdminCatchesTrackInfo($tagNumber: String!) {
    adminCatchesTrackInfo(tagNumber: $tagNumber) {
      id
      firstName
      lastName
      address
      longitude
      latitude
      statusNote
      createdAt
    }
  }
`;
