const initialState = {
    CartItems: [],
  };
  
  const CartReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ADD_TO_CART":
        return {
          ...state,
          CartItems: [...state.CartItems, action.payload],
        };
      case "REMOVE_FROM_CART":
        return {
          ...state,
          CartItems: state.CartItems.filter(
            (item) => item.id !== action.payload.id
          ),
        };
      case "REMOVE_ALL_FROM_CART":
        return {
          ...state,
          CartItems: [],
        };
      case "UPDATE_CART_ITEM":
        return {
          ...state,
          CartItems: state.CartItems.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        };
      default:
        return state;
    }
  };
  
  export default CartReducer;
  