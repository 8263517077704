export const addToCart = (item) => {
  return {
    type: "ADD_TO_CART",
    payload: item,
  };
};



export const removeFromCart = (item) => {
  return {
    type: "REMOVE_FROM_CART",
    payload: item,
  };
};

export const updateCartItem = (updatedItem) => ({
  type: "UPDATE_CART_ITEM",
  payload: updatedItem,
});

export const removeAllFromCart = () => ({
  type: "REMOVE_ALL_FROM_CART",
});
