import React, { useState } from "react";
import logo from "../../assets/images/new_logo.png";
import logoImg from "../../assets/images/login_img.png";
import passwordIcon from "../../assets/images/password_icon.svg";
import backIcon from "../../assets/images/back_arrow_icon.svg";
import eyeIcon from "../../assets/images/eye_icon.svg";
import passwordShow from "../../assets/images/password_hide_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../query/AuthQuery";

const ResetPassword = () => {
  const [passwordData, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD);
  const email = localStorage.getItem("buyerEmail");
  const [showDataPassword, setShowDataPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const togglePasswordVisibilityPassword = () => {
    setShowDataPassword(!showDataPassword);
  };
  const togglePasswordVisibilityNew = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedPasswordData = passwordData.trim();
    const trimmedNewPassword = newPassword.trim();
    try {
      if (!trimmedPasswordData) return toast.error("New password is required");
      if (!trimmedNewPassword)
        return toast.error("Confirm new password is required");
      if (trimmedNewPassword !== trimmedPasswordData)
        return toast.error("Password does not match");
      const response = await resetPassword({
        variables: {
          email: email.trim(),
          newPassword: trimmedPasswordData,
          confirmPassword: trimmedNewPassword,
        },
        context: {
          headers: {
            "X-GraphQL-Operation-Name": "SellerLoginResponse",
          },
        },
      });
      // toast.success(response?.data?.adminResetPassword?.message);
      console.log(response);
      navigate("/");
      localStorage.removeItem("laughifyEmail");
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error(error?.message);
    }
  };
  return (
    <div class="bg-white flex items-center h-screen justify-center">
      <div class="w-full px-[15px] sm:w-full lg:w-1/2 xl:w-1/2  sm:px-[15px] xl:px-0 flex items-center justify-center">
        {/*  <div class="fixed top-[20px] sm:top-[90px] left-[20px] md:top-[80px] md:left-[130px] xl:top-[115px] xl:left-[106px]">
          <button class="w-[40px] h-[40px] rounded-lg flex items-center justify-center border-solid border-[#D1D5DB] border">
            <img src={backIcon} alt="" />
          </button> 
        </div> */}
        <div class="w-full sm:w-full md:w-[500px] lg:w-[450px] xl:w-[550px]">
          <div
            href="#"
            class="static sm:fixed  top-[20px] left-[20px] w-full text-center inline-block sm:w-full xl:w-[120px]">
            <img
              class="mx-auto sm:mx-0   w-[110px] xl:w-[120px]"
              src={logo}
              alt=""
            />
          </div>
          <div class="mt-[10px] mb-[15px] sm:my-[20px] xl:my-[34px] text-center xl:text-left">
            <h2 class="font-bold text-[22px] sm:text-[24px] xl:text-[32px]  text-[#4F4F4F]">
              Reset Password
            </h2>
            <p class="text-[#4F4F4F] text-[16px]">
              Please Enter your new password
            </p>
          </div>
          <div class="w-full relative mb-[10px]">
            <input
              class="text-sm sm:text-sm md:text-base  h-[50px]  w-full  border-2  border-[#E0E0E0] border-solid rounded-[10px] px-[50px]  xl:px-[65px] pr-0 xl:pr-[65px] sm:h-[50px] xl:h-[70px]"
              placeholder="Password"
              type={showDataPassword ? "text" : "password"}
              value={passwordData}
              onChange={(e) => setPassword(e.target.value)}
              name=""
              id=""
            />
            <span class="absolute -translate-y-1/2 top-1/2 left-5">
              <img class="w-[22px] xl:w-auto" src={passwordIcon} alt="" />
            </span>

            <span
              onClick={togglePasswordVisibilityPassword}
              class="cursor-pointer absolute -translate-y-1/2 top-1/2 right-5">
              <img src={showDataPassword ? passwordShow : eyeIcon} alt="" />
            </span>
          </div>
          <div class="w-full relative mb-[10px]">
            <input
              class="text-sm sm:text-sm md:text-base  h-[50px]  w-full  border-2  border-[#E0E0E0] border-solid rounded-[10px] px-[50px]  xl:px-[65px] pr-0 xl:pr-[65px] sm:h-[50px] xl:h-[70px]"
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="Retype New Password"
              name=""
              id=""
            />
            <span class="absolute -translate-y-1/2 top-1/2 left-5">
              <img class="w-[22px] xl:w-auto" src={passwordIcon} alt="" />
            </span>
            <span
              onClick={togglePasswordVisibilityNew}
              class="cursor-pointer absolute -translate-y-1/2 top-1/2 right-5">
              <img src={showNewPassword ? passwordShow : eyeIcon} alt="" />
            </span>
          </div>
          <div class="w-full mt-[15px] xl:mt-[34px]">
            <button
              onClick={handleSubmit}
              class="flex justify-center items-center w-full rounded-[10px] font-bold bg-[#161941]  text-white text-sm sm:text-sm md:text-base h-[45px] sm:h-[50px]  xl:h-[70px]">
              {loading ? <div className="upload_loader"></div> : "Submit"}
            </button>
          </div>
        </div>
      </div>
      <div class="w-1/2 xl:w-1/2 hidden lg:inline-block">
        <img class="h-screen w-full" src={logoImg} alt="" />
      </div>
    </div>
  );
};

export default ResetPassword;
