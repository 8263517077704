import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import fishIcon from "../assets/images/fish_icon.svg";
import settingIcon from "../assets/images/setting_icon.svg";
import orderIcon from "../assets/images/orders_icon.svg";
import cartIcon from "../assets/images/cart_icon.svg";
import logoIcon from "../assets/images/dashboard_logo_new.png";
import barIcon from "../assets/images/bar_icon.svg";
import timesIcon from "../assets/images/times_icon.svg";

function SideBarPage({ isSidebarVisible, setSidebarVisible }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const location = useLocation();

  const menuItems = [
    {
      label: "Fish Available",
      icon: fishIcon,
      link: "/fish-available",
    },
    {
      label: "My Order",
      icon: orderIcon,
      link: "/my-order",
    },
    {
      label: "My Cart",
      icon: cartIcon,
      link: "/my-cart",
    },
    {
      label: "Settings",
      icon: settingIcon,
      link: "/settings",
    },
  ];

  useEffect(() => {
    const path = location.pathname;
    const index = menuItems.findIndex((item) => item.link === path);
    if (index !== -1) {
      setActiveIndex(index);
      localStorage.setItem("buyerActiveIndex", index);
    }
  }, [location, menuItems]);

  const handleClick = (index) => {
    setActiveIndex(index);
    localStorage.setItem("buyerActiveIndex", index);
    setSidebarVisible(false);
  };

  const handleSidebarToggle = () => {
    const newState = !isSidebarVisible;
    setSidebarVisible(newState);
  };

  return (
    <div>
      <div
        className={`overflow-y-auto fixed left-0 top-0 w-64 h-full shadow-md shadow-black/20 bg-white py-3 px-4 lg:py-6 lg:px-7 z-50 sidebar-menu transition-transform ${
          isSidebarVisible ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0`}
      >
        <div className="flex side-btn-main justify-between w-full pr-0 mb-14">
          <div className="flex side-logo items-center">
            <img className="w-[121px] hidden lg:inline-block"  src={logoIcon} alt="Logo" />
          </div>
          <button
            className="hidden lg:inline-block"
            onClick={handleSidebarToggle}
          >
            <img src={barIcon} alt="Menu" />
          </button>
          <button className="lg:hidden" onClick={handleSidebarToggle}>
            <img className="w-4" src={timesIcon} alt="Close" />
          </button>
        </div>

        <ul className="mt-4 menu-tabs">
          {menuItems?.map((item, index) => (
            <li key={index} className="mb-3.5 group">
              <Link
                to={item.link}
                onClick={() => handleClick(index)}
                className={`flex gap-3 font-semibold items-center py-[16px] px-[22px] text-primary hover:bg-[#161941] hover:text-white rounded-[10px] group-[.active]:bg-gray-800 group-[.active]:text-white group-[.selected]:bg-gray-950 group-[.selected]:text-gray-100 sidebar-dropdown-toggle ${
                  activeIndex === index ? "active-menu" : ""
                }`}
              >
                <img
                  className="group-hover:brightness-0 group-hover:invert"
                  src={item.icon}
                  alt={item.label}
                />
                <span className="text-[0px] hidden lg:inline-block lg:text-sm">{item.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {isSidebarVisible && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black/50 z-40 lg:hidden sidebar-overlay"
          onClick={handleSidebarToggle}
        ></div>
      )}
    </div>
  );
}

export default SideBarPage;
