import { gql } from "@apollo/client";

export const GET_ALL_FISH = gql`
  query GetBuyerFishInventories {
    getBuyerFishInventories {
      id
      speciesName
      buyerPrice
      image
      quantityInStock
    }
  }
`;

export const GET_BUYER_CATCH_BY_FISH = gql`
  query GetBuyerCatchesBuFish($fishId: ID!) {
    getBuyerCatchesBuFish(fishId: $fishId) {
      id
      weight
      image
      price
    }
  }
`;

export const GET_FISH_DETAILS = gql`
  query GetBuyerCatchByFish($catchId: ID!) {
    getBuyerCatchByFish(catchId: $catchId) {
      id
      buyerCatchInfo {
        fishSpecies
        weight
        tagNumber
        dateTime
        location
        story
        images
        longitude
        latitude
      }
      buyerSellerInfo {
        name
        email
        image
        phoneNumber {
          dialCode
          number
          countryCode
        }
      }
      catchSummary {
        pricePerLbs
        quantity
        totalPrice
      }
    }
  }
`;
