import React, { useEffect, useRef, useState } from "react";
import Logout from "../assets/images/logout_icon.svg";
import profileIcon from "../assets/images/profile_icon.svg";
import barIcon from "../assets/images/bar_icon.svg";
import logo from "../assets/images/dashboard_logo_new.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_BUYER_DATA } from "../query/AuthQuery";
import { useDispatch, useSelector } from "react-redux";
import { setSellerData } from "../redux/slice/sellerSlice";
import warning from "../assets/images/warning_icon.svg";
import userIcon from "../assets/images/usericon.png";

const HeaderPage = ({
  name,
  imageUrl,
  setSidebarVisible,
  setOverlayActive,
}) => {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [logout, setLogout] = useState(false);
  const dispatch = useDispatch();
  const sellerDataOne = useSelector((state) => state.seller.sellerData);

  const handleImageClick = () => {
    navigate(-1);
  };

  const toggleDropdown = (event) => {
    event.stopPropagation();
    setIsDropdownOpen((prevState) => !prevState);
    setOverlayActive((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
      setOverlayActive(false);
    }
  };

  const handleLogoutClick = () => {
    setLogout(true);
    setIsDropdownOpen(false);
    setOverlayActive(false);
  };

  const handleCancelClick = () => {
    setLogout(false);
  };

  const onConfirmLogout = () => {
    localStorage.removeItem("buyerToken");
    navigate("/");
    setLogout(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const token = localStorage.getItem("buyerToken");

  const { data: sellerData } = useQuery(GET_BUYER_DATA, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-GraphQL-Operation-Name": "GetBuyerData",
      },
    },
  });

  // console.log("seller-data-one", sellerDataOne);

  useEffect(() => {
    if (sellerData?.buyer) {
      dispatch(setSellerData(sellerData?.buyer));
    }
  }, [sellerData?.buyer]);

  useEffect(() => {
    setIsDropdownOpen(false);
    setOverlayActive(false);
  }, [location]);

  return (
    <>
      <div className="bg-[#FAFBFF] px-[15px] header-main pb-0 lg:pb-6 gap-y-10 lg:gap-y-0 py-6 lg:px-6 xl:px-12  flex-wrap lg:flex-nowrap justify-between flex items-center sticky top-0 left-0 z-30">
        <button
          type="button"
          className="text-lg order-1 p-2 bg-[#161941] rounded-md text-gray-900 font-semibold sidebar-toggle lg:hidden"
          onClick={() => setSidebarVisible(true)}>
          <img src={barIcon} className="brightness-0 invert" alt="Menu" />
        </button>
        <div className="flex justify-start items-center order-4 pb-3 sm:pb-4  lg:pb-0 w-full lg:order-1 lg:w-auto">
          {imageUrl && (
            <img
              style={{ cursor: "pointer" }}
              src={imageUrl}
              alt="Back"
              className="pe-2"
              onClick={handleImageClick}
            />
          )}
          <h2 className="text-primary text-[26px] font-semibold">{name}</h2>
        </div>
        <div className="order-2 pl-[10px] lg:hidden">
          <div>
            <img className="w-[110px] sm:w-[150px]" src={logo} alt="Logo" />
          </div>
        </div>
        <ul className="order-3 lg:order-2 md:m-0 lg:ml-auto  flex items-center">
          <li className="dropdown  relative">
            <button
              type="button"
              className="dropdown-toggle flex items-center"
              onClick={toggleDropdown}>
              <div className="flex-shrink-0 w-[46px] sm:w-[50px] h-[46px] sm:h-[50px] relative rounded-full">
                <div className="bg-white rounded-full w-full h-full focus:outline-none focus:ring">
                  <img
                    className="w-full h-full rounded-full object-cover"
                    src={
                      sellerDataOne?.personalInfo?.image
                        ? sellerDataOne?.personalInfo?.image
                        : userIcon
                    }
                    alt="Profile"
                  />
                </div>
              </div>
            </button>

            {isDropdownOpen && (
              <ul
                ref={dropdownRef}
                className={`absolute right-0 mt-2 w-[254px] cursor-pointer rounded-[10px] dropdown-menu shadow-md shadow-black/5 z-50 py-1.5 bg-white border border-gray-100 ${
                  isDropdownOpen ? "" : "hidden"
                }`}>
                <div className="text-center border-b border-[#C4C4C4] py-5">
                  <span className="mx-auto block w-20 h-20 rounded-full">
                    <img
                      className="w-full h-full rounded-full object-cover mx-auto"
                      src={
                        sellerDataOne?.personalInfo?.image
                          ? sellerDataOne?.personalInfo?.image
                          : userIcon
                      }
                      alt="Profile"
                    />
                  </span>
                  <span className="block mt-4 font-semibold text-lg">
                    {sellerDataOne?.personalInfo?.name}
                  </span>
                  <p className="text-sm text-[#828282]">
                    {sellerDataOne?.personalInfo?.email}
                  </p>
                </div>
                <span className="w-0 h-0 absolute -top-[10px] right-[15px] border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-white"></span>
                <li className="my-4">
                  <Link
                    to="/settings"
                    className="flex gap-3 items-center text-base px-4 text-[#4F4F4F] font-medium">
                    <img src={profileIcon} alt="Profile Icon" /> My Profile
                  </Link>
                </li>
                <li className="mb-4">
                  <div onClick={() => handleLogoutClick()}>
                    <div
                      role="menuitem"
                      className="flex gap-3 items-center text-base px-4 text-[#EB5757] cursor-pointer">
                      <img src={Logout} alt="Logout Icon" /> Log Out
                    </div>
                  </div>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
      {logout && (
        <div className="fixed top-0 left-0 w-full h-full bg-black/50 z-[11111]">
          <div className="w-[340px] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 pt-5 pb-9 bg-white rounded-[10px] text-center">
            <span className="w-full">
              <img
                className="mx-auto w-auto h-auto"
                src={warning}
                alt="Warning"
              />
            </span>
            <h3 className="text-lg font-bold py-3">Please Confirm!</h3>
            <p className="text-sm">Are you sure, you want to logout?</p>
            <div className="mt-6 flex gap-3 justify-center">
              <button
                className="w-[80px] h-[36px] rounded-[10px] text-[#161941] bg-[#ECEDFF] text-sm font-semibold"
                onClick={handleCancelClick}>
                Cancel
              </button>
              <button
                onClick={onConfirmLogout}
                className="w-[80px] h-[36px] rounded-[10px] text-white bg-[#161941] text-sm font-semibold">
                Log Out
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderPage;
