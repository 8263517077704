import React, { useEffect, useState } from "react";
import editIcon from "../../assets/images/setting_edit_icon.svg";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import rightIcon from "../../assets/images/right_tick_icon.svg";
import password from "../../assets/images/password_hide_icon.svg";
import Loading from "../../common/Loading/Loading";
import passwordShow from "../../assets/images/action_eye_icon.svg";
import timesIcon from "../../assets/images/times_icon.svg";
import pdfImage from "../../assets/images/pdf_images.png";
import placeholderImg from "../../assets/images/logo-placeholder.jpg";
import {
  CHANGE_PASSWORD,
  EDIT_BUYER,
  GET_ALL_FISH_SPECIES,
  GET_BUYER_DATA,
} from "../../query/AuthQuery";
import { useDispatch } from "react-redux";
import { setSellerData } from "../../redux/slice/sellerSlice";
import toast from "react-hot-toast";

const CrossIcon = ({ onClick }) => (
  <svg
    onClick={(e) => {
      e.stopPropagation(); // Prevent the click event from propagating to parent elements
      onClick();
    }}
    xmlns="http://www.w3.org/2000/svg"
    className="w-4 h-4 ml-2 cursor-pointer"
    fill="none"
    viewBox="0 0 24 24"
    stroke="red">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

function Settings() {
  const [openTab, setOpenTab] = useState(1);
  const [passwordOne, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isEditAble, setIsEditable] = useState(false);
  const [confirmPasssword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const token = localStorage.getItem("buyerToken");
  const [showDataPassword, setShowDataPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [ein, setEin] = useState("");
  const [ss, setSs] = useState("");
  const [phone, setPhone] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [role, setRole] = useState("");
  const [address, setAddress] = useState("");
  const [fileName, setFileName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [category, setCategory] = useState("");
  const [taxFile, setTaxFile] = useState(null);
  const [taxFileUrl, setTaxFileUrl] = useState("");
  const [newTaxUrl, setNewTaxUrl] = useState(null);
  const [companyImage, setCompanyImage] = useState(null);
  const [companyImageUrl, setCompanyImageUrl] = useState("");
  const [newCompUrl, setNewCompUrl] = useState("");
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [newUrl, setNewUrl] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const { loading: fishLoading, data: fishData } = useQuery(
    GET_ALL_FISH_SPECIES,
    {
      context: {
        headers: {
          "X-GraphQL-Operation-Name": "GetAllFishSpecies",
        },
      },
    }
  );

  // console.log("tokkkkkkkkkk", token);

  const handleEditAble = () => {
    setIsEditable(!isEditAble);
  };

  const togglePasswordVisibilityPassword = () => {
    setShowDataPassword(!showDataPassword);
  };
  const togglePasswordVisibilityNew = () => {
    setShowNewPassword(!showNewPassword);
  };
  const togglePasswordVisibilityConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const {
    data: sellerData,
    loading: dataLoading,
    refetch,
  } = useQuery(GET_BUYER_DATA, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-GraphQL-Operation-Name": "GetBuyerData",
      },
    },
  });

  console.log("-------------", sellerData);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleLogoutClick = () => {
    setShowPassword(true);
  };

  const handleCancelClick = () => {
    setShowPassword(false);
  };

  useEffect(() => {
    if (sellerData) {
      const sellerDataOne = sellerData?.buyer;
      setName(sellerDataOne?.personalInfo?.name);
      setEmail(sellerDataOne?.personalInfo?.email);
      setDialCode(sellerDataOne?.personalInfo?.phoneNumber?.dialCode);
      setPhone(sellerDataOne?.personalInfo?.phoneNumber?.number);
      setAddress(sellerDataOne?.personalInfo?.address);
      setEin(sellerDataOne?.personalInfo?.ein);
      setSs(sellerDataOne?.personalInfo?.ss);
      setRole(sellerDataOne?.personalInfo?.role);
      setImageUrl(sellerDataOne?.personalInfo?.image);
      setCompanyName(sellerDataOne?.companyInfo?.name);
      setCompanyWebsite(sellerDataOne?.companyInfo?.website);
      setCompanyImageUrl(sellerDataOne?.companyInfo?.logo);
      setNewTaxUrl(sellerDataOne?.companyInfo?.certificate);
      setSelectedCategories(sellerDataOne?.companyInfo?.species);
      // // setCategory(sellerDataOne?.companyInfo?.species);
      const species = sellerDataOne?.companyInfo?.species;
      if (Array.isArray(species)) {
        setCategory(species);
        setSelectedCategories(species);
      } else {
        setCategory([]);
        setSelectedCategories([]);
      }
    }
  }, [sellerData]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImage(file);
      setNewUrl(imageUrl);
    }
  };

  const handleCompanyChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setCompanyImage(file);
      setNewCompUrl(imageUrl);
    }
  };

  const handleTaxChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setTaxFile(file);
      setNewTaxUrl(imageUrl);
      setFileName(file?.name);
    }
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      // Add the selected value to both category and selectedCategories arrays
      setCategory((prevCategory) =>
        prevCategory ? [...prevCategory, value] : [value]
      );
      setSelectedCategories((prevCategories) => [...prevCategories, value]);
    } else {
      setCategory((prevCategory) =>
        prevCategory.filter((item) => item !== value)
      );
      setSelectedCategories((prevCategories) =>
        prevCategories.filter((category) => category !== value)
      );
    }
  };

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  console.log("cate");

  const handleRemoveCategory = (categoryToRemove) => {
    // e.stopPropagation();
    setCategory((prevCategory) =>
      prevCategory.filter((item) => item !== categoryToRemove)
    );
    setSelectedCategories((prevCategories) =>
      prevCategories.filter((category) => category !== categoryToRemove)
    );
    setIsOpen(false);
  };

  const displayCategories = selectedCategories?.map((category, index) => (
    <div key={index} className="flex items-center bg-[#e0e0e0] chip-tab">
      <span className="flex items-center me-2">{category}</span>
      <CrossIcon onClick={() => handleRemoveCategory(category)} />
    </div>
  ));

  const [editSeller, { loading: editLoading, error: editError }] = useMutation(
    EDIT_BUYER,
    {
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-GraphQL-Operation-Name": "EditBuyer",
        },
      },
    }
  );

  const handleEditSeller = async (e) => {
    e.preventDefault();
    try {
      const personalInfoReq = {
        name: name,
        phoneNumber: {
          dialCode: dialCode,
          number: phone,
          countryCode: dialCode,
        },
        ein: ein,
        address: address,
        role: role,
        oldImageUrl: image ? "" : imageUrl,
        newImageFile: image || null,
      };

      const companyInfoReq = {
        name: companyName,
        website: companyWebsite,
        species: category,
        oldLogoUrl: companyImage ? "" : companyImageUrl,
        newLogoFile: companyImage || null,
        oldCertificateUrl: taxFile ? "" : newTaxUrl,
        newCertificateFile: taxFile || null,
      };

      const { data: mutationData } = await editSeller({
        variables: {
          personalInfoReq,
          companyInfoReq,
        },
      });

      if (mutationData) {
        const { data: updatedData } = await refetch();
        if (updatedData?.admin) {
          dispatch(setSellerData(updatedData?.admin));
        }
        toast.success(mutationData?.buyerEditProfile?.message);
      }
      setIsEditable(false);
    } catch (e) {
      console.error("Error updating admin:", e);
      toast.error(e?.message);
    }
  };

  const [changePassword, { loading: mutationLoading }] = useMutation(
    CHANGE_PASSWORD,
    {
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
          "X-GraphQL-Operation-Name": "AddCatch",
        },
      },
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!passwordOne) {
      toast.error("Current Password is required");
      return;
    }
    if (!newPassword) {
      toast.error("New password is required");
      return;
    }
    if (!confirmPasssword) {
      toast.error("Confirm Password is required");
      return;
    }

    if (newPassword !== confirmPasssword) {
      toast.error("New password and Confirm Password should be same");
      return;
    }

    const passwordFormat =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordFormat.test(newPassword)) {
      toast.error(
        "Password must contain uppercase, lowercase,special character and number and 8 characters."
      );
      return;
    }
    try {
      const { data: responseData } = await changePassword({
        variables: {
          currentPassword: passwordOne,
          newPassword: newPassword,
          confirmPassword: confirmPasssword,
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-GraphQL-Operation-Name": "BuyerChangePassword",
          },
        },
      });
      toast.success("Password updated successfully.");
      setPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setShowPassword(false);
    } catch (error) {
      console.error("Error adding catch:", error);
      toast.error(error?.message);
    }
  };

  const getInitials = (name) => {
    const initials = name
      .split(" ")
      .map((word) => word.charAt(0))
      .join("");
    return initials.toUpperCase();
  };

  return (
    <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
      {dataLoading ? (
        <div className="absolute top-1/2 left-1/2">
          <Loading />
        </div>
      ) : (
        <div className="w-full rounded-[10px] overflow-hidden">
          <div className="flex-wrap gap-y-4 w-full p-4 pb-2 sm:pb-5 sm:p-5 lg:p-7 lg:pr-14 flex bg-white justify-between items-center">
            <div className="w-full sm:w-auto">
              <span className="font-semibold text-xl text-black">
                Basic Details
              </span>
              <p className="text-sm text-[#6B7280] tracking-[1px] mt-2">
                View & manage your basic profile details for the admin account
              </p>
            </div>
            <div className="flex justify-between sm:justify-normal w-full sm:w-auto gap-5">
              <button
                onClick={handleEditAble}
                className="w-10 h-10 sm:w-[40px] sm:h-[40px] flex items-center justify-center rounded-[10px] border-[2px] border-[#CCCCCC]">
                <img className="w-5 sm:w-auto" src={editIcon} alt="Edit" />
              </button>
              <button
                onClick={handleEditSeller}
                className="h-[40px] sm:h-auto sm:py-2 px-6 rounded-md text-white bg-[#161941] text-base font-normal sm:font-bold">
                {editLoading ? <div className="upload_loader"></div> : "Save"}
              </button>
            </div>
          </div>
          <div className="w-full">
            <div className="flex px-0 sm:px-5 lg:px-8 bg-white border-b border-[#EDEDED]">
              <button
                onClick={() => setOpenTab(1)}
                className={`w-1/2 sm:w-auto relative font-medium text-lg sm:text-lg py-4 sm:py-5 px-8 outline-none focus:shadow-outline-blue transition-all duration-300 ${
                  openTab === 1
                    ? "bg-transparent text-[#161941] dropline"
                    : "text-[#828282]"
                }`}>
                Personal
              </button>
              <button
                onClick={() => setOpenTab(2)}
                className={`w-1/2 sm:w-auto relative font-medium text-lg sm:text-lg py-4 sm:py-5 px-8 outline-none focus:shadow-outline-blue transition-all duration-300 ${
                  openTab === 2
                    ? "bg-transparent text-[#161941] dropline"
                    : "text-[#828282]"
                }`}>
                Company
              </button>
            </div>

            {openTab === 1 && (
              <div className="pt-4 sm:pt-5 lg:pt-11 transition-all duration-300 bg-white">
                <div className="flex flex-wrap sm:flex-nowrap px-4 sm:px-5 lg:px-8 w-full pb-14 gap-9">
                  <div className="w-44 h-32 sm:h-44 xl:w-44 xl:h-44">
                    <label
                      className="text-[#6B7280] mb-3 sm:mb-0 text-sm tracking-[1px] font-semibold"
                      htmlFor="">
                      Your Profile Picture
                    </label>
                    <div className="w-28 h-28 sm:w-full sm:h-full relative">
                      {newUrl || imageUrl ? (
                        <img
                          className="w-full object-cover h-full rounded-[10px]"
                          src={newUrl || imageUrl}
                          alt=""
                        />
                      ) : (
                        <div className="w-full h-full bg-gray-200 flex items-center justify-center rounded-[10px] text-xl font-bold text-gray-600">
                          {getInitials(name)}
                        </div>
                      )}
                      {isEditAble && (
                        <button className="absolute bottom-0 right-0 w-8 h-8 rounded-full bg-white flex items-center justify-center">
                          <img width="h-auto w-auto" src={editIcon} alt="" />
                          <input
                            type="file"
                            readOnly={!isEditAble}
                            disabled={!isEditAble}
                            accept=".jpg, .jpeg, .png"
                            onChange={handleImageChange}
                            className="cursor-pointer z-10 opacity-0 w-full h-full absolute top-0 left-0"
                          />
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="w-full xl:w-[calc(100%-196px)]">
                    <div className="w-full">
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-6">
                        <div>
                          <label
                            className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                            htmlFor="">
                            Name
                          </label>
                          <input
                            className={`border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full ${
                              !isEditAble ? "cursor-not-allowed opacity-50" : ""
                            }`}
                            type="text"
                            value={name}
                            readOnly={!isEditAble}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Henry"
                          />
                        </div>
                        <div>
                          <label
                            className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                            htmlFor="">
                            Email
                          </label>
                          <input
                            className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                            type="email"
                            value={email}
                            readOnly
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mb-6 gap-6">
                        <div>
                          <label
                            className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                            htmlFor="">
                            Mobile Number
                          </label>
                          <input
                            className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                            type="text"
                            value={`${
                              sellerData?.buyer?.personalInfo?.phoneNumber
                                ?.dialCode || ""
                            } ${
                              sellerData?.buyer?.personalInfo?.phoneNumber
                                ?.number || ""
                            }`}
                            placeholder="+000 23645235421"
                          />
                        </div>
                        <div>
                          <label
                            className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                            htmlFor="">
                            Role
                          </label>
                          <select
                            class={`focus:outline-none  md:text-base w-full border border-[#E0E0E0] border-solid rounded-[10px] px-[15px] xl:px-[15px] pr-0 xl:pr-0 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] ${
                              !isEditAble ? "cursor-not-allowed opacity-50" : ""
                            }`}
                            name=""
                            value={role}
                            readOnly={!isEditAble}
                            disabled={!isEditAble}
                            onChange={(e) => setRole(e.target.value)}
                            id="">
                            <option readOnly class="text-black" value="">
                              Select Role
                            </option>
                            <option
                              disabled={!isEditAble}
                              class="text-black"
                              value="Chef">
                              Chef
                            </option>
                            <option
                              disabled={!isEditAble}
                              class="text-black"
                              value="Restaurant">
                              Restaurant
                            </option>
                            <option
                              disabled={!isEditAble}
                              class="text-black"
                              value="Retail">
                              Retail
                            </option>
                          </select>
                        </div>
                        <div>
                          <label
                            className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                            htmlFor="">
                            Password
                          </label>
                          <div className="relative">
                            <input
                              className="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6]  text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                              type="password"
                              placeholder="xxxxxxxxxxxxx"
                            />
                            <button
                              onClick={handleLogoutClick}
                              className="text-[#161941] text-sm font-bold absolute top-1/2 -translate-y-1/2 right-3">
                              Change
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {openTab === 2 && (
              <div className="pt-4 sm:pt-5 lg:pt-8 transition-all duration-300 bg-white">
                <div class="flex  px-4 sm:px-5 lg:px-8 flex-wrap xl:flex-nowrap  w-full pb-14 gap-4 sm:gap-8 justify-start">
                  <div class="flex w-full flex-wrap md:flex-nowrap xl:w-[70%] gap-14 sm:gap-9 ">
                    <div class="w-44 h-32 sm:h-44 lg:w-44 lg:h-44">
                      <label
                        class="text-[#6B7280] text-sm mb-3 sm:mb-0 block tracking-[1px] font-semibold"
                        for="">
                        Company Logo
                      </label>
                      <div className="w-28 h-28 sm:w-full sm:h-full relative">
                        {newCompUrl || companyImageUrl ? (
                          <img
                            className="w-full object-cover h-full rounded-[10px]"
                            src={newCompUrl || companyImageUrl}
                            alt=""
                          />
                        ) : (
                          <img
                            className="w-full object-cover h-full rounded-[10px]"
                            src={placeholderImg}
                            alt=""
                          />
                        )}
                        {isEditAble && (
                          <button className="absolute bottom-0 right-0 w-8 h-8 rounded-full bg-white flex items-center justify-center">
                            <img width="h-auto w-auto" src={editIcon} alt="" />
                            <input
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              readOnly={!isEditAble}
                              disabled={!isEditAble}
                              onChange={handleCompanyChange}
                              className="cursor-pointer z-10 opacity-0 w-full h-full absolute top-0 left-0"
                            />
                          </button>
                        )}
                      </div>
                    </div>
                    <div class="w-full md:w-[calc(100%-196px)]">
                      <div class="w-full">
                        <div class="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 mb-6">
                          <div>
                            <label
                              class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                              for="">
                              Company Name
                            </label>
                            <input
                              class={`border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base text-[#4B5563] px-3 h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full ${
                                !isEditAble
                                  ? "cursor-not-allowed opacity-50"
                                  : ""
                              }`}
                              type="text"
                              value={companyName}
                              readOnly={!isEditAble}
                              disabled={!isEditAble}
                              onChange={(e) => setCompanyName(e.target.value)}
                              placeholder="Henry"
                              name=""
                              id=""
                            />
                          </div>
                          <div>
                            <label
                              class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                              for="">
                              Company Website
                            </label>
                            <input
                              class={`border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base px-3 text-[#4B5563] h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full ${
                                !isEditAble
                                  ? "cursor-not-allowed opacity-50"
                                  : ""
                              }`}
                              type="email"
                              readOnly={!isEditAble}
                              disabled={!isEditAble}
                              value={companyWebsite}
                              onChange={(e) =>
                                setCompanyWebsite(e.target.value)
                              }
                              placeholder="Company Website"
                              name=""
                              id=""
                            />
                          </div>
                          <div>
                            <label
                              className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                              htmlFor="">
                              EIN/SS#
                            </label>
                            <input
                              className={`border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6]  text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full ${
                                !isEditAble
                                  ? "cursor-not-allowed opacity-50"
                                  : ""
                              }`}
                              type="text"
                              value={ein}
                              readOnly={!isEditAble}
                              disabled={!isEditAble}
                              onChange={(e) => setEin(e.target.value)}
                              placeholder="23645235421"
                            />
                          </div>
                          <div>
                            <label
                              className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                              htmlFor="">
                              Address
                            </label>
                            <input
                              className={`border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6]  text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full ${
                                !isEditAble
                                  ? "cursor-not-allowed opacity-50"
                                  : ""
                              }`}
                              type="text"
                              value={address}
                              readOnly={!isEditAble}
                              disabled={!isEditAble}
                              onChange={(e) => setAddress(e.target.value)}
                              placeholder="address"
                            />
                          </div>
                        </div>
                        {/*  <div className="w-full mb-6">
                          <label
                            className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                            htmlFor="">
                            Address
                          </label>
                          <input
                            className={`border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6]  text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full ${
                              !isEditAble ? "cursor-not-allowed opacity-50" : ""
                            }`}
                            type="text"
                            value={address}
                            readOnly={!isEditAble}
                            disabled={!isEditAble}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder="address"
                          />
                        </div> */}
                        <div className="flex gap-4 mb-4">
                          <div className="dropdown setting-fish-inrest relative mb-0 w-full">
                            <label className="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]">
                              Interested Fish Species
                            </label>
                            <label
                              readOnly={!isEditAble}
                              disabled={!isEditAble}
                              className={`dropdown-label_one border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-base text-[#4B5563] py-3 rounded-[10px] w-full ${
                                !isEditAble
                                  ? "cursor-not-allowed opacity-50"
                                  : ""
                              }`}
                              onClick={
                                isEditAble ? handleDropdownToggle : null
                              }>
                              {displayCategories?.length > 0
                                ? displayCategories
                                : "Select Category"}
                            </label>

                            {isOpen && (
                              <div className="dropdown-list mt-6">
                                {fishData?.getAllFishSpecies?.map(
                                  (item, index) => (
                                    <div className="checkbox" key={index}>
                                      <input
                                        type="checkbox"
                                        name="dropdown-group"
                                        value={item?.name}
                                        readOnly={!isEditAble}
                                        disabled={!isEditAble}
                                        className="check checkbox-custom"
                                        onChange={handleCheckboxChange}
                                        checked={selectedCategories?.includes(
                                          item?.name
                                        )}
                                      />
                                      <label className="checkbox-custom-label">
                                        {item.name}
                                        {selectedCategories?.includes(
                                          item?.name
                                        ) && <img src={rightIcon} alt="Tick" />}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex w-[30%]">
                    <div class="w-44 h-32 sm:h-44 lg:w-44 lg:h-44">
                      <label
                        class="text-[#6B7280] text-sm tracking-[1px] font-semibold"
                        for="">
                        Tax Exemption Certificate
                      </label>
                      <div className="w-28 h-28 sm:w-full sm:h-full relative">
                        <img
                          className="w-full object-cover mb-3 h-full rounded-[10px]"
                          src={pdfImage}
                          alt=""
                        />
                        {isEditAble && (
                          <button className="absolute bottom-0 right-0 w-8 h-8 rounded-full bg-white flex items-center justify-center">
                            <img width="h-auto w-auto" src={editIcon} alt="" />
                            <input
                              type="file"
                              accept=".pdf"
                              readOnly={!isEditAble}
                              disabled={!isEditAble}
                              onChange={handleTaxChange}
                              className="cursor-pointer z-10 opacity-0 w-full h-full absolute top-0 left-0"
                            />
                          </button>
                        )}
                      </div>
                      {/*  <input
                        class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base px-3 text-[#4B5563] h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                        type="email"
                        value={fileName ? fileName : newTaxUrl}
                        placeholder="Company Website"
                        name=""
                        id=""
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {showPassword && (
        <div className="fixed top-0 left-0  w-full h-full bg-black/50 z-50">
          <div className="w-[90%] sm:w-[378px] absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 p-[15px]  sm:p-[30px] bg-white  rounded-[10px] text-center">
            <div className="mb-11 flex justify-between items-center">
              <h3 className="text-xl font-medium text-black">
                Change Password
              </h3>
              <button onClick={handleCancelClick}>
                <img src={timesIcon} alt="" />
              </button>
            </div>
            <div className="w-full">
              <div className="w-full">
                <label className="text-sm text-left font-medium text-[#4F4F4F] block mb-2">
                  Current Password
                </label>
                <div className="relative w-full">
                  <input
                    type={showDataPassword ? "text" : "password"}
                    className="w-full h-11 border border-[#E0E0E0] rounded-md px-4"
                    placeholder="**********"
                    value={passwordOne}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    onClick={togglePasswordVisibilityPassword}
                    className="absolute top-1/2 right-3 -translate-y-1/2">
                    <img
                      src={showDataPassword ? password : passwordShow}
                      alt=""
                    />
                  </button>
                </div>
              </div>
              <div className="w-full my-[30px]">
                <label className="text-sm text-left font-medium text-[#4F4F4F] block mb-2">
                  Current Password
                </label>
                <div className="relative w-full">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    className="w-full h-11 border border-[#E0E0E0] rounded-md px-4"
                    placeholder="**********"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <button
                    onClick={togglePasswordVisibilityNew}
                    className="absolute top-1/2 right-3 -translate-y-1/2">
                    <img
                      src={showNewPassword ? password : passwordShow}
                      alt=""
                    />
                  </button>
                </div>
              </div>
              <div className="w-full">
                <label className="text-sm text-left font-medium text-[#4F4F4F] block mb-2">
                  Current Password
                </label>
                <div className="relative w-full ">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className="w-full h-11 border border-[#E0E0E0] rounded-md px-4"
                    placeholder="**********"
                    value={confirmPasssword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <button
                    onClick={togglePasswordVisibilityConfirm}
                    className="absolute top-1/2 right-3 -translate-y-1/2">
                    <img
                      src={showConfirmPassword ? password : passwordShow}
                      alt=""
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="flex gap-3 mt-[34px] justify-between">
              <button
                onClick={handleCancelClick}
                className="w-[89px] h-[48px] rounded-[10px] text-[#161941] bg-[#ECEDFF] text-base font-bold">
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="flex justify-center items-center w-[89px] h-[48px] rounded-[10px] text-white bg-[#161941] text-base font-bold">
                {mutationLoading ? (
                  <div className="upload_loader"></div>
                ) : (
                  "Save"
                )}{" "}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Settings;
