import { gql } from "@apollo/client";
import client from "../../client";
import { addToCart } from "./cartAction";
import toast from "react-hot-toast";

const ADD_TO_CART_MUTATION = gql`
  mutation AddToCart($input: AddToCartRequestInput!) {
    addToCart(input: $input) {
      message
    }
  }
`;

export const addToCartAsync = (input) => {
  const token = localStorage.getItem("buyerToken");

  return async (dispatch) => {
    try {
      const { data } = await client.mutate({
        mutation: ADD_TO_CART_MUTATION,
        variables: { input },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-GraphQL-Operation-Name": "AddToCart",
          },
        },
      });

      if (data?.addToCart?.message === "success") {
        toast.success("Fish added to your cart");
        dispatch(addToCart(input));
      } else {
        console.error("Failed to add to cart:", data?.addToCart?.message);
      }
    } catch (error) {
      toast.error(error.message);
      console.error("GraphQL mutation error:", error);
    }
  };
};
