import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/css/output.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "react-resizable/css/styles.css";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import client from "./client";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./redux/store";
import "react-phone-number-input/style.css";
import "react-phone-input-2/lib/bootstrap.css";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </PersistGate>
  </Provider>
);

reportWebVitals();
