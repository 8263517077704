import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import baseRoutes from "./RouteConfig";
import CommonPage from "../layouts/CommonPage";
import PrivateRoute from "./PrivateRoute";

const AllRoutes = (props) => {
  // console.log("baseRoutes", baseRoutes);
  return (
    <Fragment>
      <Routes>
        {baseRoutes?.map((route, i) => {
          let Component = route.component;
          return (
            <Route
              key={i}
              {...route}
              element={
                <CommonPage name={route.name} imageUrl={route.imageUrl}>
                  <PrivateRoute>
                    <Component />
                  </PrivateRoute>
                </CommonPage>
              }
            />
          );
        })}
      </Routes>
    </Fragment>
  );
};

export default AllRoutes;
