import React, { useState } from "react";
import { ADMIN_BUYER_ORDER_DETAIL } from "../../query/OrderQuery";
import { useQuery } from "@apollo/client";
// import { format, parseISO } from "date-fns";
import { Link, useParams } from "react-router-dom";
import grey from "../../assets/images/grey_lbs_icon.svg";
import doller from "../../assets/images/grey_dollar_icon.svg";
import Loading from "../../common/Loading/Loading";

function convertToLocalDateTime(isoString) {
  const date = new Date(isoString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  const localDate = `${month}/${day}/${year}`;
  const localTime = `${hours}:${minutes}`;

  return { localDate, localTime };
}
function convertToEndLocalDateTime(isoString) {
  const date = new Date(isoString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");

  const localEndDate = `${month}/${day}/${year}`;
  const localEndTime = `${hours}:${minutes}`;

  return { localEndDate, localEndTime };
}
const OrderDetails = () => {
  const { id } = useParams();
  const {
    loading: dataLoading,
    error,
    data: orderData,
  } = useQuery(ADMIN_BUYER_ORDER_DETAIL, {
    variables: { id: id },
  });

  console.log(orderData);

  const fixedPrice = orderData?.buyerOrderDetail?.totalAmount
    ? new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(orderData?.buyerOrderDetail?.totalAmount.toFixed(2))
    : "";

  const { localDate, localTime } = convertToLocalDateTime(
    orderData?.buyerOrderDetail?.delieveryStartTime
  );
  const { localEndDate, localEndTime } = convertToEndLocalDateTime(
    orderData?.buyerOrderDetail?.delieveryEndTime
  );

  return (
    <div class="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
      <div class="flex gap-6 flex-wrap xl:flex-nowrap">
        {dataLoading ? (
          <div className="absolute top-1/2 left-1/2">
            <Loading />{" "}
          </div>
        ) : (
          <>
            <div class="overflow-hidden w-full xl:w-[calc(100%-400px)]">
              <div class="bg-white rounded-[10px] ">
                <div class="p-4 sm:p-5 lg:py-6 lg:px-9 border-b border-[#EDEDED]">
                  <h3 class="font-medium text-lg sm:text-xl">Order Info</h3>
                </div>
                <div class="p-4 sm:p-5 lg:p-9">
                  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-6">
                    <div>
                      <label
                        class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        for="">
                        Order ID
                      </label>
                      <input
                        class="border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6] text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] text-[#4B5563] px-3  rounded-[10px] w-full"
                        type="text"
                        value={orderData?.buyerOrderDetail?.orderId}
                        placeholder="#12345"
                        name=""
                        readOnly
                        id=""
                      />
                    </div>
                    <div className="relative">
                      <label
                        class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        for="">
                        Price Paid
                      </label>
                      <input
                        class="border-none placeholder:text-[#4B5563] ps-6 font-medium bg-[#F6F6F6] text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                        type="text"
                        placeholder="$200"
                        name=""
                        readOnly
                        value={fixedPrice}
                        id=""
                      />
                      <span class="text-[#757575] absolute top-1/2 left-2">
                        $
                      </span>
                    </div>
                  </div>
                  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
                    <div class="col-span-2">
                      <label
                        class="text-sm mb-1 font-semibold tracking-[1px] text-[#757575]"
                        for="">
                        Address
                      </label>
                      <input
                        class="cursor-pointer border-none placeholder:text-[#4B5563] font-medium bg-[#F6F6F6]  text-[#4B5563] px-3 text-sm sm:text-base h-[45px] sm:h-[50px] lg:h-[54px] rounded-[10px] w-full"
                        type="text"
                        readOnly
                        value={orderData?.buyerOrderDetail?.address}
                        placeholder="Address"
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="bg-white mt-5 lg:my-11  rounded-[10px] ">
                <div class="p-4 sm:p-5 lg:py-6 lg:px-9 border-b border-[#EDEDED]">
                  <h3 class="font-medium text-lg sm:text-xl">Order Items</h3>
                </div>
                <div class="p-4 sm:p-5 lg:p-9">
                  {orderData?.buyerOrderDetail?.items?.map((item, index) => (
                    <div
                      key={index}
                      class="flex flex-wrap sm:flex-nowrap pb-4 border-b border-[#D4D4D4] mb-5 justify-between items-start gap-4 sm:gap-6">
                      <div class="w-24 h-24 sm:w-40 sm:h-36 border border-[#E0E0E0] flex items-center justify-center">
                        <img class="w-full" src={item?.image} alt="" />
                      </div>
                      <div class="w-full sm:w-[calc(100%-160px)]">
                        <div class="w-full flex justify-between ">
                          <div class="w-auto">
                            <h4 class="font-semibold text-lg text-[#333]">
                              {item?.name}
                            </h4>
                            <div class="flex gap-10 mt-3">
                              <div>
                                <span class="text-[#818181] text-[10px]">
                                  Price per lbs
                                </span>
                                <b class="flex gap-1 font-normal items-center text-[#666666] text-xl">
                                  <img src={doller} alt="" />{" "}
                                  {item?.pricePerLbs
                                    ? new Intl.NumberFormat("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(item?.pricePerLbs.toFixed(2))
                                    : ""}
                                </b>
                              </div>
                              <div>
                                <span class="text-[#818181] text-[10px]">
                                  Weight
                                </span>
                                <b class="flex gap-1 font-normal items-center text-[#666666] text-xl">
                                  <img src={grey} alt="" /> {item?.weight}
                                  lbs
                                </b>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="text-[#161941] text-base" href="">
                              <Link to={`/fish-details/${item?.catchId}`}>
                                Show Details
                              </Link>
                            </div>
                            <div class="text-[#161941] text-base">
                              <Link className="text-base text-[#161941] underline block mt-7 text-right"
                                to={`/fish-details/${item?.catchId}`}
                                state={{ setActiveTab: 2 }}>
                                Track Info
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div class="mt-4 sm:mt-6">
                          <b class="text-[#161941]  text-lg sm:text-xl lg:text-xl font-semibold">
                            $
                            {item?.price
                              ? new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(item?.price.toFixed(2))
                              : ""}
                          </b>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div class="ml-auto xl:ml-0 w-[400px]">
              <div class=" rounded-[10px] overflow-hidden">
                <div class="w-full mb-[10px]">
                  <h3 class="font-medium sm:font-bold text-lg sm:text-lg">
                    Order Summary
                  </h3>
                </div>
                <div class="w-full rounded-[10px] mb-5 bg-white ">
                  <div class="w-full py-2 px-4">
                    <span class="text-[#4B5563] font-semibold text-sm">
                      Items ({orderData?.buyerOrderDetail?.items?.length})
                    </span>
                    <ul class="p-4 border-b border-[#A2A6DE] pb-3 w-full">
                      {orderData?.buyerOrderDetail?.items?.map(
                        (item, index) => (
                          <li class="flex text-sm text-[#4B5563] w-full justify-between">
                            <span>{item?.name}</span>{" "}
                            <b>
                              $
                              {item?.price
                                ? new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(item?.price.toFixed(2))
                                : ""}
                            </b>
                          </li>
                        )
                      )}
                    </ul>
                    <div class="flex justify-between mt-4 items-center">
                      <span class="text-[#4B5563] font-bold text-sm">
                        Total Price
                      </span>
                      <span class="text-[#4B5563] font-bold text-sm">
                        $
                        {orderData?.buyerOrderDetail?.totalAmount
                          ? new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(
                              orderData?.buyerOrderDetail?.totalAmount.toFixed(
                                2
                              )
                            )
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="w-full mb-[10px]">
                  <h3 class="font-medium sm:font-bold text-lg sm:text-lg">
                    Delivery Estimation
                  </h3>
                </div>
                <div class="w-full rounded-[10px] bg-white ">
                  <div class="w-full p-4">
                    <div class="w-full mb-4">
                      <label
                        for=""
                        class="font-semibold text-[10px] text-[#757575] block mb-2 tracking-[1px]">
                        Requested Delivery Date
                      </label>
                      <input
                        type="text"
                        readOnly
                        value={orderData?.buyerOrderDetail?.delieveryEstimation}
                        placeholder="09 / 09 / 2024"
                        class="w-full h-[46px] rounded-lg bg-[#F6F6F6] px-3"
                        name=""
                        id=""
                      />
                    </div>
                    <div>
                      <label
                        for=""
                        class="font-semibold text-[10px] text-[#757575] block mb-2 tracking-[1px]">
                        Requested Delivery Time
                      </label>
                      <input
                        type="text"
                        readOnly
                        value={`${localTime} - ${localEndTime}`}
                        placeholder="00 : 00 - 00 : 00"
                        class="w-full h-[46px] rounded-lg bg-[#F6F6F6] px-3"
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                </div>
                {orderData?.buyerOrderDetail?.status === "completed" ? (
                  <div class="w-full flex justify-center rounded-[10px] items-center h-[45px] sm:h-auto sm:py-3 px-7 mt-5 bg-[#ACB0EE]">
                    <span class="text-white text-base sm:font-bold">
                      Order Completed
                    </span>
                  </div>
                ) : (
                  <div class="w-full flex justify-center rounded-[10px] items-center h-[45px] sm:h-auto sm:py-3 px-7 mt-5 bg-[#DEAC08]">
                    <span class="text-white text-base sm:font-bold">
                      Order Pending
                    </span>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;
