import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/Logo.svg";
import logoImg from "../../assets/images/login_img.png";
import emailIcon from "../../assets/images/mail_icon.svg";
import passwordIcon from "../../assets/images/password_icon.svg";
import nameIcon from "../../assets/images/name_icon.svg";
import roleIcon from "../../assets/images/role_icon.svg";
import eyeIcon from "../../assets/images/eye_icon.svg";
import fishIcon from "../../assets/images/gray_fish_icon.svg";
import zipIcon from "../../assets/images/zipcode.svg";
import backIcon from "../../assets/images/back_arrow_icon.svg";
import placeholderIcon from "../../assets/images/sign_up_placeholder_img.svg";
import websiteIcon from "../../assets/images/website_icon.svg";
import rightIcon from "../../assets/images/right_tick_icon.svg";
import docIcon from "../../assets/images/doc_icon.svg";
import uploadIcon from "../../assets/images/upload_icon.svg";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import passwordShow from "../../assets/images/password_hide_icon.svg";
import {
  GET_ALL_FISH_SPECIES,
  GET_PLACE_DETAILS,
  GET_PLACE_ID,
  SIGN_UP_BUYER,
  VERIFY_OTP_SIGNUP,
} from "../../query/AuthQuery";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { IoCloseCircleOutline } from "react-icons/io5";
import { setSignupDetails } from "../../redux/action";
import PhoneInput from "react-phone-input-2";
import addressIcon from "../../assets/images/address_icon.svg";
import Select, { components } from "react-select";

const SignUp = () => {
  const [personal, setPersonal] = useState(true);
  const [company, setCompany] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [ein, setEin] = useState("");
  const [ss, setSs] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneOne, setPhoneOne] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [dialCodeOne, setDialCodeOne] = useState("");
  const [role, setRole] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [state, setState] = useState("");
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [fileName, setFileName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordOne, setPasswordOne] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [category, setCategory] = useState("");
  const [taxFile, setTaxFile] = useState(null);
  const [companyImage, setCompanyImage] = useState(null);
  const [companyImageUrl, setCompanyImageUrl] = useState("");
  const dispatch = useDispatch();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  // const [signUpBuyer, { loading: signUpLoading }] = useMutation(SIGN_UP_BUYER);
  const [showDataPassword, setShowDataPassword] = useState(false);
  const [showDataPasswordOne, setShowDataPasswordOne] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locationOptions, setLocationOptions] = useState([]);

  const togglePasswordVisibilityPassword = () => {
    setShowDataPassword(!showDataPassword);
  };

  const togglePasswordVisibilityPasswordOne = () => {
    setShowDataPasswordOne(!showDataPasswordOne);
  };

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const personalClick = () => {
    if (!name) {
      toast.error("Name is required.");
      return;
    }
    if (!email || !emailRegex.test(email)) {
      toast.error("Enter a valid email.");
      return;
    }
    if (!phone) {
      toast.error("Enter your phone number.");
      return;
    }
    if (!selectedLocation?.label) {
      toast.error("Enter your address.");
      return;
    }
    if (!zipCode) {
      toast.error("Enter your zip code.");
      return;
    }
    if (!role) {
      toast.error("Select at least one role.");
      return;
    }
    if (!password) {
      toast.error("Enter your password.");
      return;
    }
    if (!passwordOne) {
      toast.error("Confirm password is required.");
      return;
    }
    if (password !== passwordOne) {
      toast.error("Password and confirm password do not matched.");
      return;
    }
    setPersonal(false);
    setCompany(true);
  };

  const backClick = () => {
    setPersonal(true);
    setCompany(false);
  };

  const handleCompanyImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setCompanyImage(file);
      setCompanyImageUrl(imageUrl);

      const fileMetadata = {
        name: file.name,
        type: file.type,
        size: file.size,
        // url: imageUrl,
      };
      localStorage.setItem("sellerImageMetadata", JSON.stringify(fileMetadata));
    }
  };

  const { loading: fishLoading, data: fishData } = useQuery(
    GET_ALL_FISH_SPECIES,
    {
      context: {
        headers: {
          "X-GraphQL-Operation-Name": "GetAllFishSpecies",
        },
      },
    }
  );

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setCategory((prevCategory) =>
        prevCategory ? `${prevCategory}, ${value}` : value
      );
      setSelectedCategories((prevCategories) => [...prevCategories, value]);
    } else {
      setCategory((prevCategory) =>
        prevCategory
          .split(", ")
          .filter((item) => item !== value)
          .join(", ")
      );
      setSelectedCategories((prevCategories) =>
        prevCategories.filter((category) => category !== value)
      );
    }
  };

  const combinedValue = [selectedLocation?.label, state, city, zipCode]
    .filter(Boolean)
    .join(", ");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setFileName(file.name);
      setTaxFile(file);
      const fileMetadata = {
        name: file.name,
        type: file.type,
        size: file.size,
      };
      localStorage.setItem("taxMetaData", JSON.stringify(fileMetadata));
    }
  };

  const handlePhoneChange = (phone, countryData) => {
    setDialCodeOne(countryData.dialCode);
    if (dialCodeOne && phone.startsWith(dialCodeOne)) {
      const phoneWithoutDialCode = phone.slice(dialCodeOne.length);
      setPhoneOne(phoneWithoutDialCode);
    }
    setPhone(phone);
    setDialCode(`+${countryData.dialCode}`);
  };

  const [verifyOtp, { loading: signUpLoading }] =
    useMutation(VERIFY_OTP_SIGNUP);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!companyName) return toast.error("Company name is required.");
      if (!ein) return toast.error("Enter ein value.");
      if (!category) return toast.error("Select at least one species.");

      // const response = await signUpBuyer({
      //   variables: { email: email.trim() },
      //   context: {
      //     headers: {
      //       "X-GraphQL-Operation-Name": "SellerSignup",
      //     },
      //   },
      // });

      // dispatch(setSignupDetails({ companyImage, taxFile }));
      // const signupData = {
      //   name,
      //   email,
      //   phoneOne,
      //   dialCode,
      //   ein,
      //   ss,
      //   role,
      //   combinedValue,
      //   password,
      //   companyName,
      //   companyImage,
      //   companyWebsite,
      //   category,
      //   taxFile,
      // };

      // if (companyImage && Object.keys(companyImage).length > 0) {
      //   signupData.companyImage = {
      //     name: companyImage.name,
      //     size: companyImage.size,
      //     type: companyImage.type,
      //     lastModified: companyImage.lastModified,
      //   };
      // }

      const result = await verifyOtp({
        variables: {
          personalInfoReq: {
            name: name.trim(),
            phoneNumber: {
              dialCode: dialCode.trim(),
              number: phoneOne.trim(),
              countryCode: dialCode.trim(),
            },
            email: email.trim(),
            password: password.trim(),
            ein: ein.trim(),
            role: role.trim(),
            address: combinedValue,
          },
          companyInfoReq: {
            name: companyName.trim(),
            website: companyWebsite.trim(),
            species: selectedCategories,
            logo: companyImage || null,
            certificate: taxFile || null,
          },
        },
        context: {
          headers: {
            "X-GraphQL-Operation-Name": "sellerVerifyOtpForSignup",
          },
        },
      });

      const adminToken = result?.data?.buyerVerifyOtpForSignup?.token;
      localStorage.setItem("buyerToken", adminToken);
      // localStorage.setItem("signupDetails", JSON.stringify(signupData));
      localStorage.setItem("fishCategory", selectedCategories);

      // toast.success(response?.data?.buyerSignup?.message);
      setCompany(false);
      setPersonal(false);
      navigate("/fish-available");
      // navigate("/verify-signup-otp");
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleRemoveFile = () => {
    setFileName("");
    setTaxFile(null);
  };

  const [getPlaceId, { loading: placeLoading, data: placeData }] = useLazyQuery(
    GET_PLACE_ID,
    {
      context: {
        headers: {
          "X-GraphQL-Operation-Name": "GetPlaceId",
        },
      },
    }
  );

  const { loading: detailsLoading, data: detailsData } = useQuery(
    GET_PLACE_DETAILS,
    {
      variables: {
        placeId: selectedLocation?.value || "",
      },
      context: {
        headers: {
          "X-GraphQL-Operation-Name": "GetPlaceDetails",
        },
      },
      skip: !selectedLocation?.value,
    }
  );

  useEffect(() => {
    if (detailsData && detailsData.getPlaceDetails) {
      setState(detailsData?.getPlaceDetails?.state);
      setCity(detailsData?.getPlaceDetails?.city);
      setZipCode(detailsData?.getPlaceDetails?.PinCode);
    }
  }, [detailsData]);

  useEffect(() => {
    if (placeData && placeData?.getPlaceId) {
      const locations = placeData.getPlaceId.map((place) => ({
        value: place.placeId,
        label: place.description,
      }));

      console.log(locations);
      setLocationOptions(locations);
    }
  }, [placeData]);

  const handleInputChange = (inputValue) => {
    setAddress(inputValue);
    if (inputValue) {
      getPlaceId({ variables: { input: inputValue } });
    }
  };

  return (
    <div class="bg-white flex items-center h-screen justify-center">
      {personal && (
        <div class="w-full px-[15px] sm:w-full lg:w-full xl:w-1/2 sm:px-[15px] xl:px-0 flex items-center justify-center">
          <div class="w-full sm:w-full md:w-[500px] lg:w-[640px] xl:w-[550px]">
            <div
              href="#"
              class="static sm:fixed top-[20px] left-[20px] w-full text-center inline-block sm:w-full xl:w-[120px]">
              <img
                class="mx-auto sm:mx-0 w-[110px] xl:w-[100px]"
                src={logo}
                alt=""
              />
            </div>
            <div class="w-full">
              <div class="flex items-center gap-[20px] sm:gap-[50px] justify-center">
                <div class="flex flex-col items-center custom-line relative">
                  <div class="flex items-center">
                    <div class="w-6 sm:w-8 h-6 sm:h-8 flex items-center justify-center rounded-full bg-[#161941] text-white">
                      1
                    </div>
                  </div>
                  <span class="mt-2 font-semibold text-center text-sm sm:text-lg text-[#161941]">
                    Personal Information
                  </span>
                </div>
                <div class="flex flex-col items-center">
                  <div class="flex items-center">
                    <div class="w-6 sm:w-8 h-6 sm:h-8 flex items-center justify-center rounded-full bg-[#33333380] text-white">
                      2
                    </div>
                  </div>
                  <span class="mt-2 text-sm text-center sm:text-lg text-[#A2A2A2]">
                    Company Information
                  </span>
                </div>
              </div>
            </div>
            <div class="mt-[10px] mb-[10px] sm:mb-[15px] sm:my-[20px] xl:my-[15px] xl:mb-[15px] text-center xl:text-left">
              <h2 class="font-bold text-[22px] sm:text-[24px] xl:text-[32px] text-[#4F4F4F]">
                Sign Up
              </h2>
            </div>
            <div class="flex gap-4 mb-[10px]">
              <div class="w-full relative ">
                <input
                  class="text-sm sm:text-sm md:text-base xl:text-lg w-full border border-[#E0E0E0] border-solid rounded-[10px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[60px]"
                  type="text"
                  placeholder="Name*"
                  name=""
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id=""
                />
                <span class="absolute -translate-y-1/2 top-1/2 left-5">
                  <img class="w-[22px] xl:w-auto" src={nameIcon} alt="" />
                </span>
              </div>
            </div>
            <div class="flex gap-4 mb-[10px] sm:mb-4">
              <div class="w-full relative">
                <input
                  class="text-sm sm:text-sm md:text-base xl:text-lg w-full border border-[#E0E0E0] border-solid rounded-[10px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[60px]"
                  type="email"
                  placeholder="Email*"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name=""
                  id=""
                />
                <span class="absolute -translate-y-1/2 top-1/2 left-5">
                  <img class="w-[22px] xl:w-auto" src={emailIcon} alt="" />
                </span>
              </div>
            </div>
            <div class="flex flex-wrap sm:flex-nowrap gap-3 sm:gap-4">
              <div className="w-full relative mb-0 sm:mb-[10px]">
                <div className="relative">
                  <PhoneInput
                    class="text-sm sm:text-sm md:text-base xl:text-lg w-full border border-[#E0E0E0] border-solid rounded-[10px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[60px]"
                    country={"us"}
                    enableSearch={true}
                    value={phone}
                    onChange={(phone, countryData) =>
                      handlePhoneChange(phone, countryData)
                    }
                  />
                </div>
              </div>
              <div class="w-full sm:w-1/2 relative mb-[10px]">
                <select
                  class="role-select focus:outline-none bg-white text-sm sm:text-sm md:text-base w-full border border-[#E0E0E0] border-solid rounded-[10px] px-[45px] xl:px-[55px] pr-0 xl:pr-0 h-[45px] sm:h-[50px] xl:h-[60px]"
                  name=""
                  onChange={(e) => setRole(e.target.value)}
                  id="">
                  <option readOnly class="text-black" value="Chef">
                    Select Role
                  </option>
                  <option class="text-black" value="Chef">
                    Chef
                  </option>
                  <option class="text-black" value="Restaurant">
                    Restaurant
                  </option>
                  <option class="text-black" value="Retail">
                    Retail
                  </option>
                </select>
                <span class="absolute -translate-y-1/2 top-1/2 left-5">
                  <img class="w-[22px] xl:w-auto" src={roleIcon} alt="" />
                </span>
              </div>
            </div>
            <div class="flex gap-3">
              <div class="w-full relative mb-[10px]">
                <Select
                  options={locationOptions}
                  onChange={(selectedOption) => {
                    setSelectedLocation(selectedOption);
                  }}
                  onInputChange={(inputValue) => handleInputChange(inputValue)}
                  className="text-sm  sm:text-sm md:text-base xl:text-lg w-full border border-[#E0E0E0] border-solid rounded-[10px] px-[50px] xl:px-[60px] pr-0 xl:pr-0 h-[45px] sm:h-[50px] xl:h-[60px]"
                  value={selectedLocation}
                  placeholder={"Search address"}
                  isSearchable
                  isClearable
                />
                <span class="absolute -translate-y-1/2 top-1/2 left-5">
                  <img class="w-[22px] xl:w-auto" src={addressIcon} alt="" />
                </span>
              </div>
              <div class="w-full sm:w-1/2 relative mb-[10px]">
                <input
                  class="text-sm sm:text-sm md:text-base xl:text-lg w-full border border-[#E0E0E0] border-solid rounded-[10px] px-[50px] xl:px-[60px] pr-0 xl:pr-[15px] h-[45px] sm:h-[50px] xl:h-[60px]"
                  type={"text"}
                  placeholder="Zip Code"
                  value={zipCode}
                  onChange={(e) => setZipCode(e.target.value)}
                  name=""
                  id=""
                />
                <span class="absolute -translate-y-1/2 top-1/2 left-5">
                  <img class="w-[22px] xl:w-auto" src={zipIcon} alt="" />
                </span>
              </div>
            </div>
            <div class="flex gap-4">
              <div class="w-full relative mb-0 sm:mb-[10px]">
                <input
                  class="text-sm sm:text-sm md:text-base xl:text-lg w-full border border-[#E0E0E0] border-solid rounded-[10px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[60px]"
                  type={showDataPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  name=""
                  id=""
                />
                <span class="absolute -translate-y-1/2 top-1/2 left-5">
                  <img class="w-[22px] xl:w-auto" src={passwordIcon} alt="" />
                </span>
                <span
                  onClick={togglePasswordVisibilityPassword}
                  class="cursor-pointer absolute -translate-y-1/2 top-1/2 right-5">
                  <img src={showDataPassword ? passwordShow : eyeIcon} alt="" />
                </span>
              </div>
              <div class="w-full relative mb-0 sm:mb-[10px]">
                <input
                  class="text-sm sm:text-sm md:text-base xl:text-lg w-full border border-[#E0E0E0] border-solid rounded-[10px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[60px]"
                  type={showDataPasswordOne ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={passwordOne}
                  onChange={(e) => setPasswordOne(e.target.value)}
                  name=""
                  id=""
                />
                <span class="absolute -translate-y-1/2 top-1/2 left-5">
                  <img class="w-[22px] xl:w-auto" src={passwordIcon} alt="" />
                </span>
                <span
                  onClick={togglePasswordVisibilityPasswordOne}
                  class="cursor-pointer absolute -translate-y-1/2 top-1/2 right-5">
                  <img
                    src={showDataPasswordOne ? passwordShow : eyeIcon}
                    alt=""
                  />
                </span>
              </div>
            </div>
            <div class="w-full mt-[15px] xl:mt-[15px]">
              <button
                onClick={personalClick}
                class="w-full rounded-[10px] font-bold bg-[#161941] text-white text-sm sm:text-sm md:text-base h-[45px] sm:h-[50px] xl:h-[60px]">
                SUBMIT
              </button>
            </div>
            <div class="mt-[10px] w-full md:mt-[20px] text-center">
              <p class="flex justify-center w-full gap-3 text-gray2 text-sm md:text-xl">
                Already have an account?
                <div class="text-[#161941] font-bold" href="">
                  <Link to={"/"}>Log In</Link>
                </div>
              </p>
            </div>
          </div>
        </div>
      )}
      {company && (
        <div class="w-full px-[15px] sm:w-full lg:w-full xl:w-1/2 sm:px-[15px] xl:px-0 flex items-center justify-center">
          <div class="fixed z-10 bg-white top-[20px] sm:top-[90px] left-[20px] md:top-[105px] md:left-[92px] xl:top-[28px] xl:left-[150px]">
            <button
              onClick={backClick}
              class="w-[40px] h-[40px] rounded-lg flex items-center justify-center border-solid border-[#D1D5DB] border">
              <img src={backIcon} alt="" />
            </button>
          </div>
          <div class="w-full sm:w-full md:w-[500px] lg:w-[640px] xl:w-[550px]">
            <div
              href="#"
              class="static sm:fixed top-[20px] left-[20px] w-full text-center inline-block sm:w-full xl:w-[120px]">
              <img
                class="mx-auto sm:mx-0 w-[110px] xl:w-[100px]"
                src={logo}
                alt=""
              />
            </div>
            <div class="w-full">
              <div class="flex items-center gap-[20px] sm:gap-[50px] justify-center">
                <div class="flex flex-col items-center custom-line relative">
                  <div class="flex items-center">
                    <div class="w-6 sm:w-7 h-6 sm:h-7 flex items-center justify-center rounded-full bg-[#161941] text-white">
                      1
                    </div>
                  </div>
                  <span class="text-center mt-2 text-sm sm:text-base font-semibold text-[#161941]">
                    Personal Information
                  </span>
                </div>
                <div class="flex flex-col items-center">
                  <div class="flex items-center">
                    <div class="w-6 sm:w-7 h-6 sm:h-7 flex items-center justify-center rounded-full bg-[#161941] text-white">
                      2
                    </div>
                  </div>
                  <span class="text-center mt-2 font-semibold text-sm sm:text-base text-[#161941]">
                    Company Information
                  </span>
                </div>
              </div>
            </div>
            <div class="mt-[10px] mb-[15px] sm:my-[20px] xl:my-[10px] text-center xl:text-left">
              <h2 class="font-bold text-[22px] sm:text-[24px] xl:text-[28px] text-[#4F4F4F]">
                Sign Up
              </h2>
            </div>
            <div className="w-full mb-4 flex justify-center items-center">
              <div className="w-25 mx-auto">
                <label className="text-[#6B7280] block mb-1 text-[10px] font-semibold">
                  Your Company Logo
                </label>
                <div className="rounded-md mx-auto relative bg-[#F4F4F8] w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24 border-[2px] border-dashed border-[#161941]">
                  {companyImageUrl ? (
                    <img
                      className="w-full h-full rounded-md object-cover"
                      src={companyImageUrl}
                      alt="Selected"
                    />
                  ) : (
                    <button className="w-auto h-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <img
                        className="w-5 sm:w-auto"
                        src={placeholderIcon}
                        alt="company_image"
                      />
                    </button>
                  )}
                  <input
                    type="file"
                    className="absolute top-0 cursor-pointer left-0 w-full h-full opacity-0"
                    onChange={handleCompanyImageChange}
                  />
                </div>
              </div>
            </div>
            <div class="flex gap-2 sm:gap-4">
              <div class="w-1/2 relative mb-[10px]">
                <input
                  class="text-sm placeholder:text-[#BDBDBD] sm:text-sm md:text-base w-full border border-[#E0E0E0] border-solid rounded-[10px] pl-[35px] sm:pl-[50px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[60px]"
                  type="text"
                  placeholder="Company Name"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  name=""
                  id=""
                />
                <span class="absolute -translate-y-1/2 top-1/2 left-3 sm:left-5">
                  <img class="w-[22px] xl:w-auto" src={nameIcon} alt="" />
                </span>
              </div>
              <div class="w-1/2 relative mb-[10px]">
                <input
                  class="text-sm sm:text-sm placeholder:text-[#BDBDBD] md:text-base w-full border border-[#E0E0E0] border-solid rounded-[10px] pl-[35px] sm:pl-[50px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[60px]"
                  type="text"
                  placeholder="Company Website"
                  value={companyWebsite}
                  onChange={(e) => setCompanyWebsite(e.target.value)}
                  name=""
                  id=""
                />
                <span class="absolute -translate-y-1/2 top-1/2 left-3 sm:left-5">
                  <img class="w-[22px] xl:w-auto" src={websiteIcon} alt="" />
                </span>
              </div>
            </div>
            <div class="flex gap-2 sm:gap-4 mb-[10px]">
              <div class="w-full relative">
                <input
                  class="text-sm sm:text-sm placeholder:text-[#BDBDBD] md:text-base w-full border border-[#E0E0E0] border-solid rounded-[10px] pl-[35px] sm:pl-[50px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[60px]"
                  type="number"
                  placeholder="EIN"
                  name=""
                  value={ein}
                  onChange={(e) => setEin(e.target.value)}
                  id=""
                />
                <span class="font-bold text-sm sm:text-base text-[#BDBDBD] absolute -translate-y-1/2 top-1/2 left-5">
                  EIN
                </span>
              </div>
            </div>
            <div className="flex gap-4 mb-[10px]" ref={dropdownRef}>
              <div className="dropdown relative mb-0 w-full">
                <label
                  className="dropdown-label w-full text-sm sm:text-sm md:text-base xl:text-lg border-2  border-[#E0E0E0] border-solid rounded-[10px] px-[50px]  xl:px-[65px] pr-0 xl:pr-[65px] block h-[45px] sm:h-[50px] xl:h-[70px]"
                  onClick={handleDropdownToggle}>
                  {category || "Select fish category"}
                </label>
                <span className="absolute -translate-y-1/2 top-1/2 left-5">
                  <img className="w-[22px] xl:w-auto" src={fishIcon} alt="" />
                </span>

                {isOpen && (
                  <div className="dropdown-list">
                    {fishData?.getAllFishSpecies?.map((item, index) => (
                      <div className="checkbox" key={index}>
                        <input
                          type="checkbox"
                          name="dropdown-group"
                          value={item?.name}
                          className="check checkbox-custom"
                          onChange={handleCheckboxChange}
                          checked={selectedCategories.includes(item?.name)}
                        />
                        <label className="checkbox-custom-label">
                          {item.name}
                          {selectedCategories.includes(item?.name) && (
                            <img src={rightIcon} alt="Tick" />
                          )}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div class="flex gap-4">
              <div class="w-full relative">
                <input
                  class="text-sm placeholder:text-[#BDBDBD] sm:text-sm md:text-base w-full border border-[#E0E0E0] border-solid rounded-[10px] pl-[40px] sm:pl-[50px] px-[50px] xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[60px]"
                  type="text"
                  value={fileName}
                  placeholder="Upload Tax Exempt Certificate"
                  name=""
                  id=""
                />

                {fileName && (
                  <span class="absolute -translate-y-1/2 top-1/2 right-20">
                    <IoCloseCircleOutline
                      onClick={handleRemoveFile}
                      className="remove-icon"
                    />
                  </span>
                )}
                <span class="absolute -translate-y-1/2 top-1/2 left-5">
                  <img class="w-[22px] xl:w-auto" src={docIcon} alt="" />
                </span>
                <input
                  accept="application/pdf"
                  onChange={handleFileChange}
                  type="file"
                  readOnly
                  class="cursor-pointer absolute top-0 left-0 w-full h-full opacity-0"
                />
                <span class="cursor-pointer absolute -translate-y-1/2 top-1/2 right-5">
                  <img src={uploadIcon} alt="" />
                </span>
              </div>
            </div>
            <div class="mt-5 flex justify-start w-full gap-1 sm:gap-3 text-black text-sm md:text-base">
              By signing up, I agree to{" "}
              <b className="font-bold text-[#161941] underline">
                Terms & Conditions
              </b>
            </div>
            <div class="w-full mt-[15px] xl:mt-[15px]">
              <button
                onClick={handleSubmit}
                class="flex justify-center items-center w-full rounded-[10px] font-bold bg-[#161941] text-white text-sm sm:text-sm md:text-base h-[45px] sm:h-[50px] xl:h-[60px]">
                {signUpLoading ? (
                  <div className="upload_loader"></div>
                ) : (
                  "SUBMIT"
                )}
              </button>
            </div>
            <div class="mt-[10px] w-full md:mt-[10px] text-center">
              <p class="flex justify-center w-full gap-3 text-black text-sm md:text-xl">
                Already have an account?
                <div class="text-[#161941] font-bold" href="">
                  <Link to={"/"}>Log In</Link>
                </div>
              </p>
            </div>
          </div>
        </div>
      )}
      <div class="w-1/2 xl:w-1/2 hidden xl:inline-block">
        <img class="h-screen w-full" src={logoImg} alt="" />
      </div>
    </div>
  );
};

export default SignUp;
