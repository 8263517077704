import React, { useState } from "react";
import SideBarPage from "../../common/SideBarPage";
import HeaderPage from "../../common/HeaderPage";

const CommonPage = ({ name, imageUrl, children }) => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [isOverlayActive, setOverlayActive] = useState(false);

  const handleOverlayClick = () => {
    setOverlayActive(false);
  };

  const toggleSidebar = (isActive) => {
    setSidebarVisible(isActive);
  };

  return (
    <div
      className={`commonpage text-gray-800 font-inter ${
        isOverlayActive ? "overlay-active" : ""
      } ${isSidebarVisible ? "menu-active" : ""}`}
    >
      <SideBarPage
        isSidebarVisible={isSidebarVisible}
        setSidebarVisible={toggleSidebar}
      />
      <div className="w-full bg-[#FAFBFF] main-content md:w-full lg:w-[calc(100%-256px)] lg:ml-64 min-h-screen transition-all main relative">
        <HeaderPage
          name={name}
          imageUrl={imageUrl}
          setSidebarVisible={toggleSidebar}
          setOverlayActive={setOverlayActive}
        />
        {children}
      </div>
      {isOverlayActive && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black/50 z-[999]"
          onClick={handleOverlayClick}
        ></div>
      )}
    </div>
  );
};

export default CommonPage;
