import React, { useState } from "react";
import logo from "../../assets/images/new_logo.png";
import logoImg from "../../assets/images/login_img.png";
import emailIcon from "../../assets/images/mail_icon.svg";
import backIcon from "../../assets/images/back_arrow_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD } from "../../query/AuthQuery";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [forgotPassword, { loading, error, data }] =
    useMutation(FORGOT_PASSWORD);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!email) return toast.error("Enter an email address.");
      const response = await forgotPassword({
        variables: {
          email: email,
        },
        context: {
          headers: {
            "X-GraphQL-Operation-Name": "SellerLoginResponse",
          },
        },
      });
      // toast.success(response?.data?.adminForgotPassword?.message);
      console.log(response);
      navigate("/verify-otp");

      localStorage.setItem("buyerEmail", email);

      setEmail("");
    } catch (error) {
      console.error("Error during signup:", error);
      // toast.error(error?.message);
    }
  };
  return (
    <div className="bg-white flex items-center h-screen justify-center">
      <div className="w-full px-[15px] sm:w-full lg:w-1/2 xl:w-1/2  sm:px-[15px] xl:px-0 flex items-center justify-center">
        <div className="fixed top-[20px] sm:top-[90px] left-[20px] md:top-[80px] md:left-[130px] xl:top-[115px] xl:left-[106px]">
          <button className="w-[40px] h-[40px] rounded-lg flex items-center justify-center border-solid border-[#D1D5DB] border">
            <Link to={"/"}>
              <img src={backIcon} alt="" />
            </Link>
          </button>
        </div>
        <div className="w-full sm:w-full md:w-[500px] lg:w-[450px] xl:w-[550px]">
          <div
            href="#"
            className="static sm:fixed  top-[20px] left-[20px] w-full text-center inline-block sm:w-full xl:w-[120px]">
            <img
              className="mx-auto sm:mx-0  w-[110px] xl:w-[120px]"
              src={logo}
              alt=""
            />
          </div>
          <div className="mt-[10px] mb-[15px] sm:my-[20px] xl:my-[34px] text-center xl:text-left">
            <h2 className="font-bold text-[22px] sm:text-[24px] xl:text-[32px]  text-[#4F4F4F]">
              Forgot Password?
            </h2>
            <p className="text-[#4F4F4F] text-[16px]">
              Please enter your registered email id
            </p>
          </div>
          <div className="w-full relative mb-[10px]">
            <input
              className="placeholder:text-[#BDBDBD] text-sm sm:text-sm md:text-base w-full  border-2  border-[#E0E0E0] border-solid rounded-[10px] px-[50px]  xl:px-[65px] pr-0 xl:pr-[65px] h-[45px] sm:h-[50px] xl:h-[70px]"
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name=""
              id=""
            />
            <span className="absolute -translate-y-1/2 top-1/2 left-5">
              <img className="w-[22px] xl:w-auto" src={emailIcon} alt="" />
            </span>
          </div>
          <div className="w-full mt-[15px] xl:mt-[34px]">
            <button
              onClick={handleSubmit}
              className=" w-full flex justify-center items-center rounded-[10px] font-bold bg-[#161941]  text-white text-sm sm:text-sm md:text-base h-[45px] sm:h-[50px]  xl:h-[70px]">
              {loading ? <div className="upload_loader"></div> : "Send OTP"}
            </button>
          </div>
        </div>
      </div>
      <div className="w-1/2 xl:w-1/2 hidden lg:inline-block">
        <img className="h-screen w-full" src={logoImg} alt="" />
      </div>
    </div>
  );
}

export default ForgotPassword;
