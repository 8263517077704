import { combineReducers } from "redux";
import buySignupReducer from "./signUpReducers";
import CartReducer from "./cartReducers";
import cartDataReducer from "../slice/cartSlice";
import sellerReducer from "../slice/sellerSlice";

const rootReducer = combineReducers({
  buyerSignup: buySignupReducer,
  cart: CartReducer,
  cartData: cartDataReducer,
  seller: sellerReducer,
});

export default rootReducer;
