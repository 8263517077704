import React, { useState } from "react";
import PendingOrder from "./PendingOrder";
import CompleteOrder from "./CompleteOrder";
import { useQuery } from "@apollo/client";
import { GET_ORDER } from "../../query/OrderQuery";

function MyOrder() {
  const [openTab, setOpenTab] = useState(1);
 

  return (
    <div className="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
      <div className="grid grid-cols-1 lg:grid-cols-1 mb-6 rounded-md overflow-hidden">
        {/* component */}
        <div className="relative">
          <div className="w-full">
            <div className="flex sm:px-8 bg-white">
              <button
                onClick={() => setOpenTab(1)}
                className={`w-1/2 sm:w-auto relative text-base sm:text-lg font-medium  py-5 px-8 outline-none focus:shadow-outline-blue transition-all duration-300 ${
                  openTab === 1
                    ? "bg-transparent text-[#161941] dropline"
                    : "text-[#828282]"
                }`}>
                Pending
              </button>
              <button
                onClick={() => setOpenTab(2)}
                className={`w-1/2 sm:w-auto relative font-medium text-base sm:text-lg py-5 px-8 outline-none focus:shadow-outline-blue transition-all duration-300 ${
                  openTab === 2
                    ? "bg-transparent text-[#161941] dropline"
                    : "text-[#828282]"
                }`}>
                Completed
              </button>
            </div>

            <div
              className={`transition-all duration-300 bg-white ${
                openTab === 1 ? "block" : "hidden"
              }`}>
              <PendingOrder />
            </div>

            <div
              className={`transition-all duration-300 bg-white ${
                openTab === 2 ? "block" : "hidden"
              }`}>
              <CompleteOrder />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyOrder;
