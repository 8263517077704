import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cartData",
  initialState: {
    cartData: [],
  },
  reducers: {
    setCartData: (state, action) => {
      state.cartData = action.payload;
    },
    removeItem(state, action) {
      const itemId = action.payload;
      state.cartData = state.cartData.filter((item) => item.id !== itemId);
    },
  },
});

export const { setCartData, removeItem } = cartSlice.actions;
export default cartSlice.reducer;
