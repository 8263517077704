import React, { useRef, useState } from "react";
import logo from "../../assets/images/new_logo.png";
import logoImg from "../../assets/images/login_img.png";
import backIcon from "../../assets/images/back_arrow_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useMutation } from "@apollo/client";
import { FORGOT_PASSWORD, VERIFY_OTP_FORGOT } from "../../query/AuthQuery";

const VerifyOtp = () => {
  const [verifyOtp, { loading }] = useMutation(VERIFY_OTP_FORGOT);
  const email = localStorage.getItem("buyerEmail");
  const [otpValue, setOtpValue] = useState(["", "", "", "", "", ""]);
  const otpInputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const navigate = useNavigate();

  const handleOtpChange = (index, value) => {
    const newOtpDigits = [...otpValue];
    const customValue = value.substring(value.length - 1);

    if (customValue !== "") {
      newOtpDigits[index] = customValue;

      // Move focus to next field if value is entered
      if (index < otpInputRefs.length - 1) {
        otpInputRefs[index + 1].current.focus();
      }
    } else {
      newOtpDigits[index] = "";

      // Move focus to previous field if value is cleared and index is not the first
      if (index > 0) {
        otpInputRefs[index - 1].current.focus();
      }
    }

    setOtpValue(newOtpDigits);
  };

  const handleBackspaceOrClear = (e, index) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      e.preventDefault();

      if (e.key === "Backspace" && index > 0) {
        otpInputRefs[index - 1].current.focus();
      }

      const newOtpDigits = [...otpValue];
      newOtpDigits[index] = "";
      setOtpValue(newOtpDigits);
    }
  };

  const handleSubmit = async () => {
    try {
      const otpString = otpValue.join("");
      const result = await verifyOtp({
        variables: {
          email: email.trim(),
          otp: otpString,
        },
        context: {
          headers: {
            "X-GraphQL-Operation-Name": "SellerLoginResponse",
          },
        },
      });

      console.log(result?.data);
      // toast.success(result?.data?.adminVerifyOtpForResetPassword?.message);

      navigate("/reset-password");
      // Handle success, maybe redirect or show a success message
    } catch (error) {
      console.error(error);
      toast.error(error?.message);
      // Handle error, show error message or handle it as needed
    }
  };

  const [forgotPassword, { loading: passwordLoading, error, data }] =
    useMutation(FORGOT_PASSWORD);

  const resendOtp = async (e) => {
    e.preventDefault();
    try {
      // if (!email) return toast.error("Enter an email address.");
      const response = await forgotPassword({
        variables: {
          email: email.trim(),
        },
        context: {
          headers: {
            "X-GraphQL-Operation-Name": "SellerLoginResponse",
          },
        },
      });
      toast.success(response?.data?.buyerForgotPassword?.message);
    } catch (error) {
      console.error("Error during signup:", error);
      toast.error(error?.message);
    }
  };
  return (
    <div className="bg-white flex items-center h-screen justify-center">
      <div className="w-full px-[15px] sm:w-full lg:w-1/2 xl:w-1/2 sm:px-[15px] xl:px-0 flex items-center justify-center">
        <div className="fixed top-[20px] sm:top-[90px] left-[20px] md:top-[80px] md:left-[130px] xl:top-[115px] xl:left-[106px]">
          <button className="w-[40px] h-[40px] rounded-lg flex items-center justify-center border-solid border-[#D1D5DB] border">
            <Link to={"/forgot-password"}>
              <img src={backIcon} alt="" />
            </Link>
          </button>
        </div>
        <div className="w-full sm:w-full md:w-[500px] lg:w-[450px] xl:w-[550px]">
          <div className="static sm:fixed  top-[20px] left-[20px] w-full text-center inline-block sm:w-full xl:w-[120px]">
            <img
              className="mx-auto sm:mx-0  w-[110px] xl:w-[120px]"
              src={logo}
              alt=""
            />
          </div>
          <div className="mt-[10px] mb-[15px] sm:my-[20px] xl:my-[34px] text-center xl:text-left">
            <h2 className="font-bold text-[22px] sm:text-[24px] xl:text-[32px]  text-[#4F4F4F]">
              Verifying Email
            </h2>
            <p className="text-[#4F4F4F] text-[16px]">
              Please enter the OTP sent on your email
            </p>
          </div>
          <div className="flex items-center justify-between">
            {otpValue?.map((digit, index) => (
              <input
                type="text"
                id={`digit${index + 1}`}
                pattern="[0-9]"
                required
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleBackspaceOrClear(e, index)}
                ref={otpInputRefs[index]}
                key={index}
                className="pl-[0] md:pl-[0] xl:pl-[0]  text-center font-bold text-base md:w-[60px] md:h-[60px] w-[45px] h-[45px] lg:w-[55px] lg:h-[55px] xl:w-[70px] xl:h-[70px] border border-solid rounded-lg border-[#E0E0E0]"
              />
            ))}
          </div>
          <div className="w-full mt-[15px] xl:mt-[34px]">
            <button
              onClick={handleSubmit}
              className="flex items-center justify-center w-full rounded-[10px] font-bold bg-[#161941]  text-white text-sm sm:text-sm md:text-base h-[45px] sm:h-[50px]  xl:h-[70px]">
              {loading ? <div className="upload_loader"></div> : "Verify OTP"}
            </button>
          </div>
          <div className="mt-[10px] w-full md:mt-[20px] text-center">
            <p className="flex justify-center w-full gap-3 text-gray2 text-sm md:text-lg">
              Didn't receive the email?{" "}
              <div
                onClick={resendOtp}
                className="text-[#161941] font-bold cursor-pointer flex items-center"
                href="">
                {passwordLoading && <div className="resend_loader me-2"></div>}{" "}
                Click to resend
              </div>
            </p>
          </div>
        </div>
      </div>
      <div className="w-1/2 xl:w-1/2 hidden lg:inline-block">
        <img className="h-screen w-full" src={logoImg} alt="" />
      </div>
    </div>
  );
};

export default VerifyOtp;
