import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "seller",
  initialState: {
    sellerData: null,
  },
  reducers: {
    setSellerData: (state, action) => {
      state.sellerData = action.payload;
    },
  },
});

export const { setSellerData } = adminSlice.actions;
export default adminSlice.reducer;
