export const SET_SIGNUP_DETAILS = "SET_SIGNUP_DETAILS";
export const CLEAR_SIGNUP_STATE = "CLEAR_SIGNUP_STATE";

export const setSignupDetails = (details) => ({
  type: SET_SIGNUP_DETAILS,
  payload: details,
});

export const clearSignupState = () => ({
  type: CLEAR_SIGNUP_STATE,
});