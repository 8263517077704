import React, { useEffect, useState } from "react";
import fishImage from "../../assets/images/empty_cart.png";
import dollarImage from "../../assets/images/grey_dollar_icon.svg";
import lbsImage from "../../assets/images/grey_lbs_icon.svg";
import deleteImage from "../../assets/images/delete_icon_grey.svg";
import { ADD_ORDER, DELETE_CART_ITEM, GET_CART } from "../../query/CartQuery";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { setCartData, removeItem } from "../../redux/slice/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Loading from "../../common/Loading/Loading";
import TimePicker from "react-time-picker";

const MyCart = () => {
  const [data, setData] = useState([]);
  const token = localStorage.getItem("buyerToken");
  const dispatch = useDispatch();
  const [deliveryDate, setDeliveryDate] = useState("");
  const cartDataNew = useSelector((state) => state.cartData.cartData);
  const navigate = useNavigate();
  const [loadingItems, setLoadingItems] = useState({});
  const [deliveryTimeStart, setDeliveryTimeStart] = useState("");
  const [deliveryTimeEnd, setDeliveryTimeEnd] = useState("");

  // Get current date in YYYY-MM-DD format
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const totalPrice = cartDataNew.reduce(
    (total, item) => total + item.totalPrice,
    0
  );

  const {
    loading: cartLoading,
    error,
    data: cartData,
    refetch,
  } = useQuery(GET_CART, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-GraphQL-Operation-Name": "GetCart",
      },
    },
  });
  useEffect(() => {
    if (cartData) {
      setData(cartData?.getCarts);
      dispatch(setCartData(cartData?.getCarts));
    }
  }, [cartData]);

  const [deleteCartItem, { loading: deleteCart }] =
    useMutation(DELETE_CART_ITEM);

  const handleRemoveItem = async (itemId) => {
    setLoadingItems((prevState) => ({ ...prevState, [itemId]: true }));
    try {
      const { data } = await deleteCartItem({
        variables: { id: itemId },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-GraphQL-Operation-Name": "DeletedCart",
          },
        },
      });

      if (data?.deletedCart) {
        dispatch(removeItem(itemId));
      }
    } catch (error) {
      console.error("Error removing item:", error);
    } finally {
      setLoadingItems((prevState) => ({ ...prevState, [itemId]: false }));
    }
  };

  const [
    addOrder,
    { data: orderData, loading: orderLoading, error: orderError },
  ] = useMutation(ADD_ORDER, {
    context: {
      headers: {
        Authorization: `Bearer ${token}`,
        "X-GraphQL-Operation-Name": "DeletedCart",
      },
    },
  });

  const handleDateChange = (e) => {
    setDeliveryDate(e.target.value);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}/${String(date.getDate()).padStart(2, "0")}/${date.getFullYear()}`;
    return formattedDate;
  };

  const formatDateTime = (dateString, timeString) => {
    const date = new Date(dateString);
    const [hours, minutes] = timeString.split(":").map(Number);
    date.setHours(hours, minutes, 0, 0);
    const formattedDateTime = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:00`;
    return formattedDateTime;
  };

  const handleTimeStartChange = (time) => {
    setDeliveryTimeStart(time);
  };

  useEffect(() => {
    if (deliveryTimeStart) {
      const [hours, minutes] = deliveryTimeStart.split(":").map(Number);
      const endHours = (hours + 2) % 24; // Add 2 hours to the start time
      const endMinutes = minutes;

      const formattedEndHours = String(endHours).padStart(2, "0");
      const formattedEndMinutes = String(endMinutes).padStart(2, "0");

      setDeliveryTimeEnd(`${formattedEndHours}:${formattedEndMinutes}`);
      // setDeliveryTimeEnd(`${formattedEndHours}:${formattedEndMinutes}`);
    }
  }, [deliveryTimeStart]);

  console.log(deliveryDate, deliveryTimeStart, deliveryTimeEnd);

  const handlePlaceOrder = async () => {
    const itemIdsString = cartDataNew
      .map((item) => item?.fish?.id.toString())
      .join(",");
    const catchIdsString = cartDataNew
      .map((item) => item?.catch?.id.toString())
      .join(",");

    try {
      if (!deliveryDate)
        return toast.error("Enter a delivery estimation date.");
      if (!deliveryTimeStart)
        return toast.error("Enter a delivery estimation time.");
      // const formattedDate = formatDate(deliveryDate);
      const formattedStartTime = formatDateTime(
        deliveryDate,
        deliveryTimeStart
      );
      const formattedEndTime = formatDateTime(deliveryDate, deliveryTimeEnd);
      const { data } = await addOrder({
        variables: {
          input: {
            delieveryEstimation: formatDate(deliveryDate),
            delieveryStartTime: formattedStartTime,
            delieveryEndTime: formattedEndTime,
            itemIds: itemIdsString,
            catchIds: catchIdsString,
          },
        },
        context: {
          headers: {
            Authorization: `Bearer ${token}`,
            "X-GraphQL-Operation-Name": "AddOrder",
          },
        },
      });
      console.log(
        "data?.addOrder?.isAllAvailable",
        data?.addOrder?.isAllAvailable
      );
      if (data?.addOrder?.isAllAvailable === false) {
        refetch();
        toast.error(
          "Something in your cart is out of stock, first remove that."
        );
      } else {
        navigate("/my-order");
        toast.success("Order placed successfully");
        dispatch(setCartData([]));
      }
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div class="sm:pb-0 xl:pb-0 p-4 lg:p-6 xl:p-12 pt-2 xl:pt-2 bg-[#FAFBFF]">
      <div class="flex gap-6 flex-wrap xl:flex-nowrap">
        {cartLoading ? (
          <div className="absolute top-1/2 left-1/2">
            <Loading />
          </div>
        ) : cartDataNew?.length === 0 ? (
          <div className="absolute top-[50%] sm:top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2  sm:-translate-y-1/2">
            <img
              className="w-36 sm:w-auto "
              src={fishImage}
              alt="empty_cart_image"
            />
          </div>
        ) : (
          <>
            <div class="overflow-hidden md:w-full xl:w-[calc(100%-400px)]">
              <div class="w-full mb-[10px]">
                <h3 class="font-bold text-lg">
                  Cart Items ({cartDataNew?.length})
                </h3>
              </div>
              {cartLoading ? (
                <div className="absolute top-1/2 left-1/2">
                  <Loading />
                </div>
              ) : (
                cartDataNew?.map((items, index) => (
                  <div
                    key={index}
                    className={`rounded-[10px] ${
                      items?.catch?.isAvailable === false
                        ? "bg-[#ffdddd]"
                        : "bg-white"
                    }`}>
                    <div class="p-4 lg:p-9 mb-3">
                      <div class="flex pb-4 gap-y-3 flex-wrap sm:flex-nowrap border-b border-[#D4D4D4] mb-5 justify-between items-start gap-6">
                        <div class="w-24 h-24 sm:w-36 sm:h-32 lg:w-40 lg:h-36 border border-[#E0E0E0] flex items-center justify-center">
                          <img
                            class="w-full"
                            src={items?.fish?.image}
                            alt="fish_image"
                          />
                        </div>
                        <div class="w-full sm:w-[calc(100%-160px)]">
                          <div class="w-full flex justify-between">
                            <div class="w-auto">
                              <h4 class="font-semibold text-base sm:text-lg text-[#333]">
                                {items?.fish?.name}({items?.catch?.tagNumber})
                              </h4>
                              <div class="flex gap-10 mt-3">
                                <div>
                                  <span class="text-[#818181] text-[10px]">
                                    Price per lbs
                                  </span>
                                  <b class="flex gap-1 font-normal items-center text-[#666666] text-lg lg:text-xl">
                                    <img src={dollarImage} alt="" />
                                    {items?.catch?.pricePerLbs
                                      ? new Intl.NumberFormat("en-US", {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }).format(
                                          items?.catch?.pricePerLbs.toFixed(2)
                                        )
                                      : ""}
                                  </b>
                                </div>
                                <div>
                                  <span class="text-[#818181] text-[10px]">
                                    Weight
                                  </span>
                                  <b class="flex gap-1 font-normal items-center text-[#666666] text-lg lg:text-xl">
                                    <img
                                      className="w-4 sm:w-auto"
                                      src={lbsImage}
                                      alt=""
                                    />{" "}
                                    {items?.catch?.weight} lbs
                                  </b>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div class="text-[#161941] pt-1 text-sm sm:text-base">
                                <Link to={`/fish-detail/${items?.catch?.id}`}>
                                  Show Details
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3 lg:mt-6 w-full flex justify-between items-center">
                            <b class="text-[#161941] text-lg lg:text-xl font-semibold">
                              $
                              {items?.totalPrice
                                ? new Intl.NumberFormat("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }).format(items?.totalPrice.toFixed(2))
                                : ""}
                            </b>
                            <button
                              onClick={() => handleRemoveItem(items?.id)}
                              class="flex items-center justify-center text-[#828282] text-sm gap-2">
                              {items?.catch?.isAvailable === false && (
                                <b class="text-[#ca1f27] text-lg lg:text-2xl font-semibold">
                                  Out of Stock
                                </b>
                              )}
                              {loadingItems[items?.id] ? (
                                <div className={"delete_cart_loader"}></div>
                              ) : (
                                ""
                              )}{" "}
                              <img
                                className="w-4 sm:w-auto"
                                src={deleteImage}
                                alt=""
                              />{" "}
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
            <div class="md:ml-auto lg:ml-0 w-[400px]">
              <div class="rounded-[10px] overflow-hidden">
                <div class="w-full mb-[10px]">
                  <h3 class="font-bold text-lg sm:text-lg">Order Summary</h3>
                </div>
                <div class="w-full rounded-[10px] mb-5 bg-white">
                  <div class="w-full py-2 px-4">
                    <span class="text-[#4B5563] font-semibold text-sm">
                      Items ({cartDataNew?.length})
                    </span>
                    <ul class="p-4 border-b border-[#A2A6DE] pb-3 w-full">
                      {cartDataNew?.map((item, index) => (
                        <li
                          key={index}
                          class="flex text-sm text-[#4B5563] w-full justify-between">
                          <span> {item?.fish?.name}</span>{" "}
                          <b>
                            $
                            {item?.totalPrice
                              ? new Intl.NumberFormat("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(item?.totalPrice.toFixed(2))
                              : ""}
                          </b>
                        </li>
                      ))}
                    </ul>
                    <div class="flex justify-between mt-4 items-center">
                      <span class="text-[#4B5563] font-bold text-sm">
                        Total Price
                      </span>
                      <span class="text-[#4B5563] font-bold text-sm">
                        $
                        {totalPrice
                          ? new Intl.NumberFormat("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(totalPrice.toFixed(2))
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="w-full mb-[10px]">
                  <h3 class="font-bold text-lg sm:text-lg">
                    Delivery Estimation
                  </h3>
                </div>
                <div class="w-full rounded-[10px] bg-white">
                  <div class="w-full p-4">
                    <div class="w-full mb-4">
                      <label
                        for=""
                        class="font-semibold text-[10px] text-[#757575] block mb-2 tracking-[1px]">
                        Requested Delivery Date
                      </label>
                      <input
                        type="date"
                        placeholder="mm/dd/yyyy"
                        class="w-full h-[46px] rounded-lg bg-[#F6F6F6] px-3"
                        name=""
                        min={getCurrentDate()}
                        value={deliveryDate}
                        onChange={handleDateChange}
                        id=""
                      />
                    </div>
                    <div>
                      <label
                        for=""
                        class="font-semibold text-[10px] text-[#757575] block mb-2 tracking-[1px]">
                        Requested Delivery Time
                      </label>
                      <div className="flex justify-between items-center gap-3">
                        <TimePicker
                          onChange={handleTimeStartChange}
                          className="w-full h-[46px] rounded-lg bg-[#F6F6F6]"
                          value={deliveryTimeStart}
                          format="HH:mm"
                          disableClock={true}
                        />
                        <TimePicker
                          value={deliveryTimeEnd}
                          className="w-full h-[46px] rounded-lg bg-[#F6F6F6]"
                          format="HH:mm"
                          disableClock={true}
                          readOnly={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  onClick={handlePlaceOrder}
                  class="w-full flex justify-center rounded-[10px] items-center py-3 sm:py-3 px-7 mt-5 bg-[#161941]">
                  <span class="text-white text-base font-semibold sm:font-bold cursor-pointer">
                    {orderLoading ? (
                      <div className="upload_loader"></div>
                    ) : (
                      "Place Order"
                    )}{" "}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MyCart;
