import React, { useRef, useState } from "react";
import logo from "../../assets/images/new_logo.png";
import logoImg from "../../assets/images/login_img.png";
import backIcon from "../../assets/images/back_arrow_icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { VERIFY_OTP_SIGNUP } from "../../query/AuthQuery";
import toast from "react-hot-toast";
import { clearSignupState } from "../../redux/action";

const OtpScreen = () => {
  const [verifyOtp, { loading: signUpLoading }] =
    useMutation(VERIFY_OTP_SIGNUP);
  const dispatch = useDispatch();
  const [otpValue, setOtpValue] = useState(["", "", "", "", "", ""]);
  const navigate = useNavigate();
  const category = localStorage.getItem("fishCategory");
  const categoryArray = category ? category.split(",") : [];
  const sellerSignupData = JSON.parse(localStorage.getItem("signupDetails"));
  const buyerSignup = useSelector((state) => state.buyerSignup);
  const otpInputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  console.log("buyerSignup", categoryArray);

  const handleOtpChange = (index, value) => {
    const newOtpDigits = [...otpValue];
    const customValue = value.substring(value.length - 1);
    newOtpDigits[index] = customValue;
    setOtpValue(newOtpDigits);

    if (value === "" && index > 0) {
      otpInputRefs[index - 1].current.focus();
    } else if (value && index < otpInputRefs.length - 1) {
      otpInputRefs[index + 1].current.focus();
    }
  };

  const handleBackspaceOrClear = (e, index) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      e.preventDefault();

      if (e.key === "Backspace" && index > 0) {
        otpInputRefs[index - 1].current.focus();
      }

      const newOtpDigits = [...otpValue];
      newOtpDigits[index] = "";
      setOtpValue(newOtpDigits);
    }
  };

  const handleSubmit = async () => {
    try {
      const otpString = otpValue.join("");
      const result = await verifyOtp({
        variables: {
          personalInfoReq: {
            name: sellerSignupData?.name.trim(),
            phoneNumber: {
              dialCode: sellerSignupData?.dialCode.trim(),
              number: sellerSignupData?.phoneOne.trim(),
              countryCode: sellerSignupData?.dialCode.trim(),
            },
            email: sellerSignupData?.email.trim(),
            password: sellerSignupData?.password.trim(),
            ein: sellerSignupData.ein.trim(),
            role: sellerSignupData.role.trim(),
            address: sellerSignupData.combinedValue,
          },
          companyInfoReq: {
            name: sellerSignupData.companyName.trim(),
            website: sellerSignupData.companyWebsite.trim(),
            species: categoryArray,
            logo: buyerSignup.companyImage || null,
            certificate: buyerSignup.taxFile || null,
          },
          otp: otpString,
        },
        context: {
          headers: {
            "X-GraphQL-Operation-Name": "sellerVerifyOtpForSignup",
          },
        },
      });

      dispatch(clearSignupState());
      navigate("/fish-available");
      const adminToken = result?.data?.buyerVerifyOtpForSignup?.token;
      localStorage.setItem("buyerToken", adminToken);
      // Handle success, maybe redirect or show a success message
    } catch (error) {
      console.error(error);
      toast.error(error?.message);
    }
  };

  return (
    <div className="bg-white flex items-center h-screen justify-center">
      <div className="w-full px-[15px] sm:w-full lg:w-1/2 xl:w-1/2 sm:px-[15px] xl:px-0 flex items-center justify-center">
        <div className="fixed top-[20px] sm:top-[90px] left-[20px] md:top-[80px] md:left-[130px] xl:top-[115px] xl:left-[106px]">
          <button className="w-[40px] h-[40px] rounded-lg flex items-center justify-center border-solid border-[#D1D5DB] border">
            <Link to={"/sign-up"}>
              <img src={backIcon} alt="" />{" "}
            </Link>
          </button>
        </div>
        <div className="w-full sm:w-full md:w-[500px] lg:w-[450px] xl:w-[550px]">
          <div
            href="#"
            className="static sm:fixed  top-[20px] left-[20px] w-full text-center inline-block sm:w-full xl:w-[120px]">
            <img
              className="mx-auto sm:mx-0  w-[110px] xl:w-[120px]"
              src={logo}
              alt=""
            />
          </div>
          <div className="mt-[10px] mb-[15px] sm:my-[20px] xl:my-[34px] text-center xl:text-left">
            <h2 className="font-bold text-[22px] sm:text-[24px] xl:text-[32px]  text-[#4F4F4F]">
              Verifying Email
            </h2>
            <p className="text-[#4F4F4F] text-[16px]">
              Please enter the OTP sent on your email
            </p>
          </div>
          <div className="flex items-center justify-between">
            {otpValue?.map((digit, index) => (
              <input
                type="text"
                id={`digit${index + 1}`}
                pattern="[0-9]"
                required
                value={digit}
                onChange={(e) => handleOtpChange(index, e.target.value)}
                onKeyDown={(e) => handleBackspaceOrClear(e, index)}
                ref={otpInputRefs[index]}
                key={index}
                className="pl-[18px] md:pl-[25px] font-bold text-base md:w-[60px] md:h-[60px] w-[50px] h-[50px] lg:w-[70px] lg:h-[70px] xl:w-[70px] border border-solid rounded-lg border-[#E0E0E0]"
              />
            ))}
          </div>
          <div className="w-full mt-[15px] xl:mt-[34px]">
            <button
              onClick={handleSubmit}
              className="flex justify-center items-center w-full rounded-[10px] font-bold bg-[#161941]  text-white text-sm sm:text-sm md:text-base h-[50px]  xl:h-[70px]">
              {signUpLoading ? (
                <div className="upload_loader"></div>
              ) : (
                "Verify OTP"
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="w-1/2 xl:w-1/2 hidden lg:inline-block">
        <img className="h-screen w-full" src={logoImg} alt="" />
      </div>
    </div>
  );
};

export default OtpScreen;
